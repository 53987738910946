import React, { useState } from 'react';
import { Button } from '@mui/material';

function HudControls({ toggleAlert, toggleMachine, toggleCharacterState }) {
  const [alertEnabled, setAlertEnabled] = useState(false);
  const [activeMachine, setActiveMachine] = useState(1);
  const [characterState, setCharacterState] = useState('idle');
  
  // Define available character states
  const characterStates = ['idle', 'walking'];
  
  const handleAlertToggle = () => {
    const newState = !alertEnabled;
    setAlertEnabled(newState);
    toggleAlert(newState);
  };
  
  const handleMachineToggle = () => {
    const newMachine = activeMachine === 1 ? 2 : 1;
    setActiveMachine(newMachine);
    toggleMachine(newMachine);
  };
  
  const handleCharacterStateToggle = () => {
    // Rotate through available states
    const currentIndex = characterStates.indexOf(characterState);
    const nextIndex = (currentIndex + 1) % characterStates.length;
    const newState = characterStates[nextIndex];
    
    setCharacterState(newState);
    toggleCharacterState(newState);
  };
  
  return (
    <div style={{
      position: 'absolute',
      top: '20px',
      right: '20px',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    }}>
      <Button 
        variant={alertEnabled ? "contained" : "outlined"} 
        color={alertEnabled ? "error" : "primary"}
        onClick={handleAlertToggle}
        sx={{ fontWeight: 'bold' }}
      >
        {alertEnabled ? "Disable Alert" : "Simulate Alert"}
      </Button>
      
      <Button 
        variant="outlined" 
        color="primary"
        onClick={handleMachineToggle}
        sx={{ fontWeight: 'bold' }}
      >
        Active: CNC-{activeMachine === 1 ? "01" : "02"}
      </Button>
      
      <Button 
        variant="outlined" 
        color="secondary"
        onClick={handleCharacterStateToggle}
        sx={{ fontWeight: 'bold' }}
      >
        Character: {characterState.charAt(0).toUpperCase() + characterState.slice(1)}
      </Button>
    </div>
  );
}

export default HudControls;