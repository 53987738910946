import React from 'react';
import { mesh } from '@react-three/drei';

const FallbackBox = ({ position }) => {
  return (
    <mesh position={position} castShadow>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color="red" />
    </mesh>
  );
};

export default FallbackBox;