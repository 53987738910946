import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import { Col, Flex, Row, Tree, Typography } from 'antd';
import { Box } from '@mui/material';
import './Proveit.css';
import { Content } from 'antd/es/layout/layout';

const { DirectoryTree } = Tree;
const { Title } = Typography;

const Proveit = () => {
  const [mqttMessage, setMqttMessage] = useState({ 'Tulip_Experience_Center': {} });
  const [allMessages, setAllMessages] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  const [antTree, setAntTree] = useState([]);
  const [hotTopic, setHotTopic] = useState();
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [newTopics, setNewTopics] = useState([]);
  const topic = `Enterprise/tulip/#`;

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const updateMessageTree = (prevMessages, newMessage) => {
    return { ...prevMessages, ...mergeDeep(prevMessages, newMessage) };
  };

  const applyBlinkingEffect = (topicKey) => {
    const allElements = document.querySelectorAll('[data-topic]');
    allElements.forEach((element) => {
      if (element.dataset.topic.toLowerCase() === topicKey.toLowerCase()) {
        element.classList.add('blinking');
        setHotTopic(topicKey.toLowerCase());

        setTimeout(() => {
          element.classList.remove('blinking');
          setHotTopic(null);
        }, 2000);
      }
    });
  };

  const convertToTreeData = (obj, parentKey = '0', keyCounter = { current: 0 }) => {
    const treeNodes = [];

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const nodeKey = `${parentKey}-${keyCounter.current}`;
        const nodeTitle = key;
        const children = obj[key];

        if (typeof children === 'object' && children !== null && Object.keys(children).length === 0) {
          continue;
        }

        const treeNode = {
          title: nodeTitle,
          key: nodeKey,
          'data-topic': children._topic || parentKey, // Assign data-topic to all nodes
        };

        if (children && children.Value !== undefined && children.Value !== null) {
          let valueStr;
          if (typeof children.Value === 'object') {
            const jsonString = JSON.stringify(children.Value);
            valueStr = jsonString.length > 50 ? jsonString.substring(0, 50) + "..." : jsonString;
          } else {
            valueStr = children.Value.toString();
          }

          treeNode.title = (
            <span>
              <span>{nodeTitle}: </span>
              <span className="node-value">{valueStr}</span>
            </span>
          );
          treeNode.isLeaf = true;
          treeNodes.push(treeNode);
        } else if (typeof children === 'object' && children !== null) {
          const childNodes = convertToTreeData(children, nodeKey, keyCounter);
          if (childNodes.length > 0) {
            treeNode.children = childNodes;
            treeNodes.push(treeNode);
          }
        } else {
          treeNode.isLeaf = true;
        }

        keyCounter.current++;
      }
    }

    return treeNodes;
  };

  const mergeDeep = (target = {}, source = {}) => {
    for (const key in source) {
      if (source.hasOwnProperty(key)) {
        if (source[key] instanceof Object && key in target) {
          if (Object.keys(source[key]).length === 0) {
            delete target[key];
          } else {
            target[key] = mergeDeep(target[key], source[key]);
          }
        } else {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

  const structureMessageByTopic = (topic, msg) => {
    const levels = topic.split('/');
    const nestedMessage = levels.reduceRight((acc, level) => {
      if (acc && typeof acc === 'object' && Object.keys(acc).length !== 0) {
        if (levels[levels.length - 1] === level) {
          acc['_topic'] = topic;
        }
      }

      return { [level]: acc };
    }, msg);
    return nestedMessage;
  };

  // Apply blinking effect after DOM updates
  useEffect(() => {
    if (newTopics.length > 0) {
      console.log('New topics to blink:', newTopics);

      // Use setTimeout to ensure the DOM is updated before applying the blinking effect
      setTimeout(() => {
        newTopics.forEach(topic => {
          applyBlinkingEffect(topic);
        });
        setNewTopics([]); // Clear new topics after applying effect
      }, 0); // Delay of 0ms ensures this runs after the DOM update
    }
  }, [newTopics, antTree]); // Add antTree as a dependency to ensure DOM is updated

  useEffect(() => {
    const client = mqtt.connect('wss://a2417032a8f1427685fda167966172a5.s2.eu.hivemq.cloud:8884/mqtt', {
      username: 'hivemq.webclient.1693222052513',
      password: '7P&TLW3Qf.k0am5d*V#t',
      rejectUnauthorized: false,
    });

    setMqttMessage({});
    setAntTree([]);
    setAllMessages({});

    client.on('connect', () => {
      setIsConnected(true);
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Subscribed to topic: ${topic}`);
        } else {
          console.error('Subscription error: ', err);
        }
      });
    });

    client.on('message', (topic, message) => {
      let msg = {};
      try {
        msg = { data: JSON.parse(message.toString()), topic: topic };
      } catch (error) {
        console.error("Failed to parse JSON:", error, "Message:", message.toString());
        return;
      }

      const tree = structureMessageByTopic(topic, msg.data);
      const topicKey = topic;
      const newValue = msg.data.Value;

      setAllMessages(prev => {
        const oldMessage = prev[topicKey];
        const isNewTopic = !oldMessage;

        if (isNewTopic) {
          setNewTopics(prevTopics => [...prevTopics, topicKey]); // Add new topic to newTopics
        } else if (oldMessage.Value !== newValue) {
          applyBlinkingEffect(topicKey); // Apply blinking for updates
        }

        return { ...prev, [topicKey]: msg.data };
      });

      setMqttMessage(prevMessages => updateMessageTree(prevMessages, tree));
      const newAntTree = convertToTreeData(updateMessageTree(mqttMessage, tree));
      setAntTree(newAntTree);

      const allKeys = [];
      const flattenKeys = (nodes) => {
        nodes.forEach(node => {
          allKeys.push(node.key);
          if (node.children) {
            flattenKeys(node.children);
          }
        });
      };
      flattenKeys(newAntTree);
      setExpandedKeys(allKeys);
    });

    client.on('error', (err) => {
      console.error('Connection error: ', err);
    });

    return () => {
      client.end();
    };
  }, []);

  return (
    <Box sx={{ background: `linear-gradient(to right, #00263e, #003b71)`, height: '100vh' }}>
      <Row justify="space-between" className='antContainer' sx={{ height: '100%' }}>
        <Col span={6} className={'unsFlex'} sx={{ height: '100%' }}>
          <Flex vertical>
            <Title level={5} style={{ color: '#F2EEA1', marginTop: '5px' }}>Unified Name Space</Title>
            <Content className='customScroll'>
              <div style={{ flex: "1 1 auto", maxHeight: "100%" }}>
                <DirectoryTree
                  treeData={antTree}
                  expandedKeys={expandedKeys}
                  showLine={true}
                  showIcon={false}
                  className={'tree'}
                  selectable={false}
                />
              </div>
            </Content>
          </Flex>
        </Col>
        <Col flex="1">
        </Col>
      </Row>
    </Box>
  );
};

export default Proveit;