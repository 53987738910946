import { useParams } from "react-router-dom";
import { useApp } from "../../hooks/useApp";
import { useAuth } from "../../hooks/useAuth";
import { useAnon } from "../../hooks/useAnon";
import { useSegment } from "../../hooks/useSegment";
import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { Container, Grid, useTheme } from "@mui/material";
import { Typography } from "antd";
import LaunchIcon from "@mui/icons-material/Launch";
import ProgressLoader from "../../components/Common/ProgressLoader";
import { useDocs } from "../../hooks/useDocs";

const AssetPage = () => {
  const { assetId, account } = useParams();
  const { vtecAssetsUnsorted : vtecAssets, setActiveVideo } = useApp();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const { user } = useAuth();
  const { trackDirectEvent } = useSegment();
  const { loginAnon } = useAnon();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState(null);
  const { getUserDeets } = useDocs("partnerDetails");
  useEffect(() => {
    if (!(account && assetId && vtecAssets))
      return;

    const getDeets = async () => {
      const gotDeets = await getUserDeets(account);
      if (!gotDeets) {
        setErrorMessage("Invalid Account")
        setShowLoader(false);
        return;
      }
      if (!gotDeets.approved && !gotDeets.email.includes('@tulip.co')) {
        setErrorMessage("Account not approved")
        setShowLoader(false);
        return;
      }
      var asset = vtecAssets?.filter((asset) => asset.Type !== "factory" && asset.Type !== "megafactory" && asset.id === assetId)[0];
      if (asset) {
        setSelectedAsset(asset);
      }
      else{
        setErrorMessage("Invalid Asset Id")
      }
      setShowLoader(false);
      if (user) {
        trackDirectEvent("asset opened", asset, user?.privateAccount?.name ?? "Tulip");
      } else {
        loginAnon(gotDeets.privateId).then(() => {
          trackDirectEvent("asset opened", asset, "Tulip");
        });
      }
    }
    getDeets();
  }, [account, assetId ,vtecAssets]);
  const formatTimestamp = ({ seconds, nanoseconds }) => {
    const milliseconds = seconds * 1000 + Math.floor(nanoseconds / 1_000_000);
    const date = new Date(milliseconds);

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const openURL = (url) => {
    window.open(url, "_blank");
  };
  const handleVideo = (url) => {
    setActiveVideo(url);
  };
  return (
    <div>
      <Navbar showMenu={false}/>
      {showLoader ? (
        <ProgressLoader />
      ) : selectedAsset ? (
        <Container className="py-6">
          <Typography variant="h2" className="text-3xl md:text-5xl mb-5">
            {selectedAsset.Title}
          </Typography>
          <Typography className="text-base md:text-lg mb-6">
            {selectedAsset.desc}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <div className="rounded-xl overflow-hidden">
                {selectedAsset.video ? (
                  <iframe
                    src={selectedAsset.video}
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    muted
                    frameborder="0"
                    allowtransparency="true"
                    scrolling="no"
                    style={{
                      width: "100%",
                      height: "445px",
                    }}
                  ></iframe>
                ) : (
                  <img src={selectedAsset.Image} />
                )}
              </div>
            </Grid>
            <Grid item xs={5}>
              <ul className="flex flex-wrap gap-5 lg:pl-3 pt-1">
                <li className="w-[calc(50%_-_12px)]">
                  <strong class="block text-base font-bold">
                    Content Type
                  </strong>
                  <div class="flex flex-wrap items-center text-p-small">
                    <div class="ml-1">{selectedAsset.Type}</div>
                  </div>
                </li>
                <li className="w-[calc(50%_-_12px)]">
                  <strong class="block text-base font-bold">Developer</strong>
                  <div class="flex flex-wrap items-center text-p-small">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="none"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      class="mr-1"
                    >
                      <rect
                        width="24"
                        height="24"
                        fill="#003B71"
                        rx="12"
                      ></rect>
                      <path
                        fill="#fff"
                        d="M11.359 18.833 6 13.696v-5.1l5.358 5.137zm.657-6.277L8.253 8.948l3.763-3.615 3.77 3.615zm.665 6.277 5.319-5.1V8.626l-5.32 5.107z"
                      ></path>
                    </svg>{" "}
                    Tulip
                  </div>
                </li>
                {selectedAsset?.createdAt && (
                  <li className="w-[calc(50%_-_12px)]">
                    <strong class="block text-base font-bold">Created</strong>
                    <div class="flex flex-wrap items-center text-p-small">
                      {formatTimestamp(selectedAsset.createdAt)}
                    </div>
                  </li>
                )}

                <li className="w-[calc(50%_-_12px)]"></li>
                {Array.isArray(selectedAsset?.Features) &&
                  selectedAsset.Features.length > 0 && (
                    <li className="w-full">
                      <strong class="block text-base font-bold">
                        Features
                      </strong>
                      <div class="flex flex-wrap items-center text-p-small gap-2 mt-1.5 mb-3">
                        {selectedAsset?.Features.map((item, index) => (
                          <div
                            key={index}
                            class="rounded-full border px-2.5 py-0.5 font-[350] flex w-fit items-center text-sm font-semibold"
                            style={{
                              backgroundColor:
                                theme.palette.customColors.highlight,
                              color: theme.palette.customColors.highlightText,
                            }}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </li>
                  )}
                {selectedAsset.video ? (
                  <div
                    className="chip"
                    onClick={() => handleVideo(selectedAsset.video)}
                  >
                    View Video{" "}
                    <LaunchIcon fontSize="small" sx={{ paddingLeft: 1 }} />
                  </div>
                ) : null}
                {selectedAsset.appUrl ? (
                  <div
                    className="chip"
                    onClick={() => openURL(selectedAsset.appUrl)}
                  >
                    Open Player{" "}
                    <LaunchIcon fontSize="small" sx={{ paddingLeft: 1 }} />
                  </div>
                ) : null}
                {selectedAsset.editorUrl ? (
                  <div
                    className="chip"
                    onClick={() => openURL(selectedAsset.editorUrl)}
                  >
                    Open Editor
                    <LaunchIcon fontSize="small" sx={{ paddingLeft: 1 }} />
                  </div>
                ) : null}
                {selectedAsset.contentUrl ? (
                  <div
                    className="chip"
                    onClick={() => openURL(selectedAsset.contentUrl)}
                  >
                    More info
                    <LaunchIcon fontSize="small" sx={{ paddingLeft: 1 }} />
                  </div>
                ) : null}
              </ul>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container className="py-6">
          <Typography className="text-3xl md:text-5xl text-center text-red-800">
            {errorMessage}
          </Typography>
        </Container>
      )}
    </div>
  );
};

export default AssetPage;
