import React, { Suspense, useState, useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import * as THREE from 'three';
import { Box, Button, ButtonGroup, duration } from '@mui/material';
import { PolishedConcreteMaterial, YellowLines } from './Concrete';
import { PointLightArray } from './LightArrays';
import { InteriorWalls } from './Walls';
import { CustomModel } from './CustomModel';
import CharacterManager from './CharacterManager';
import HudControls from './HudControls';
import MachineGroup from './MachineGroup';

// Main component for the 3D scene
const ModelScene = () => {
  const [alertActive, setAlertActive] = useState(false);
  const [activeMachine, setActiveMachine] = useState(1);
  
  // Reference to the character manager
  const characterManagerRef = useRef();
  // Define all waypoints in the scene

  const waypointRefs = {
    mainStCenterX: 12.5,
    mainStCenterZ: 7.5,

  }
  const waypoints = {
    forkliftHome: { x: 3, y: 0, z: -30 , color:"blue"},
    forkliftDriveway: { x: waypointRefs.mainStCenterX, y: 0, z: -30 , color:"blue"},
    intersection: { x: waypointRefs.mainStCenterX, y: 0, z: waypointRefs.mainStCenterZ , color:"blue"},
    mainCross1A: { x: 16.9, y: 0, z: 3.1 , color:"blue"},
    mainCross1B: { x: 8, y: 0, z: 3.1 , color:"blue"},
    mainCross2A: { x: 16.9, y: 0, z: 11.9 , color:"blue"},
    mainCross2B: { x: 8, y: 0, z: 11.9 , color:"blue"},
    cnc01: { x: -4, y: 0, z: .5 , color:"blue"},
    cnc02: { x: 3, y: 0, z: .5 , color:"blue"},
    dmboard1: {x: 22-1, z: waypointRefs.mainStCenterZ+1},
    dmboard2: {x: 22-1, z: waypointRefs.mainStCenterZ-1}
  };
  

//   [{x:-25, z:11.9}, {x:8.85, z:11.9}],
//   [{x:15.85, z:11.9}, {x:9.15, z:11.9}],
//   [{x:15.85, z:3.1}, {x:9.15, z:3.1}],
//   [{x:-25, z:3.1}, {x:8.85, z:3.1}],
//   [{x:16.9, z:-35}, {x:16.9, z:35}],

  // Define character configurations
  const characterConfigurations = [
    {
      id: 'worker1',
      position: [-4, 0, .5],
      offset: [0, 0, 0],
      animationStates: {
        idle: '/render/Idle.fbx',
        walking: '/render/Walking.fbx',
        salute: '/render/Salute.fbx',
      },
      defaultState: 'idle',
      scale: 0.015,
      actions: {
        // Patrol between waypoints
        patrol: {
          steps: [
            { type: 'move', target: waypoints.mainCross1B },
            { type: 'wait', duration: 1000 },
            { type: 'move', target: waypoints.mainCross1A },
            { type: 'move', target: waypoints.dmboard1 },
          ]
        },
        // Check machine 1
        checkMachine1: {
          steps: [
            { type: 'move', target: { x: -4, y: 0, z: 0 } },
            { type: 'animate', animation: 'salute', duration: 2000 },
            { type: 'wait', duration: 1000 },
            { type: 'move', target: { x: -5, y: 0, z: 5 } },
          ]
        },
        // Salute action
        salute: {
          steps: [
            { type: 'animate', animation: 'salute', duration: 3000 },
          ]
        }
      }
    },
    {
      id: 'supervisor',
      position: [5, 0, 5],
      offset: [0, 0, 0],
      animationStates: {
        idle: '/render/Idle.fbx',
        walking: '/render/Walking.fbx',
        salute: '/render/Salute.fbx',
      },
      defaultState: 'idle',
      scale: 0.015,
      actions: {
        // Inspect both machines
        inspectMachines: {
          steps: [
            { type: 'move', target: { x: -4, y: 0, z: 0 } },
            { type: 'animate', animation: 'salute', duration: 2000 },
            { type: 'move', target: { x: 4, y: 0, z: 0 } },
            { type: 'animate', animation: 'salute', duration: 2000 },
            { type: 'move', target: { x: 5, y: 0, z: 5 } },
          ]
        },
        // Check machine 2
        checkMachine2: {
          steps: [
            { type: 'move', target: { x: 4, y: 0, z: 0 } },
            { type: 'animate', animation: 'salute', duration: 2000 },
            { type: 'wait', duration: 1000 },
            { type: 'move', target: { x: 5, y: 0, z: 5 } },
          ]
        }
      }
    }
  ];
  
  // Define vehicle configurations (forklift with driver)
  const vehicleConfigurations = [
    {
      id: 'forklift1',
      vehicleModel: '/render/forklift.glb',
      position: [10, 0, 0],
      rotation: [0, 0, 0],
      scale: 0.017,
      // Driver configuration - sitting in the forklift
      driverModel: '/render/Driving.fbx', // Assuming you have this FBX or replace with appropriate one
      driverPosition: [0, 60, -50], // Adjust to position driver in seat
      driverRotation: [0, 0, 0], // Facing forward in forklift
      driverScale: .9,
      // Actions specific to forklift
      actions: {
        // Transport materials between locations
        transportMaterials: {
          steps: [
            { type: 'move', target: { x: 20, y: 0, z: 0 } }, // Move to pickup
            { type: 'wait', duration: 2000 }, // Loading time
            { type: 'move', target: { x: -15, y: 0, z: 15 } }, // Move to dropoff
            { type: 'wait', duration: 2000 }, // Unloading time
            { type: 'move', target: { x: 10, y: 0, z: 0 } }, // Return to starting position
          ]
        },
        // Make rounds to check inventory
        inventoryCheck: {
          steps: [
            { type: 'move', target: { x: 20, y: 0, z: 0 } },
            { type: 'wait', duration: 1000 },
            { type: 'move', target: { x: 20, y: 0, z: 15 } },
            { type: 'wait', duration: 1000 },
            { type: 'move', target: { x: -15, y: 0, z: 15 } },
            { type: 'wait', duration: 1000 },
            { type: 'move', target: { x: 10, y: 0, z: 0 } },
          ]
        },
        // Drive to specific machine to service it
        serviceMachine: {
          steps: [
            { type: 'move', target: { x: -4, y: 0, z: 0 } }, // Move to machine 1
            { type: 'wait', duration: 3000 }, // Service time
            { type: 'move', target: { x: 10, y: 0, z: 0 } }, // Return to starting position
          ]
        }
      }
    }
  ];
  
  
  // Toggle alert state
  const toggleAlert = (state) => {
    setAlertActive(state);
  };
  
  // Toggle active machine
  const toggleMachine = (machineNum) => {
    setActiveMachine(machineNum);
  };
  
  // Handler for character action completion
  const handleActionComplete = (entityId, actionName) => {
    console.log(`Action complete - Entity: ${entityId}, Action: ${actionName}`);
    // You can add specific logic here based on which character completed which action
  };
  
  // Function to execute a specific action for a character
  const executeCharacterAction = (characterId, actionName) => {
    if (characterManagerRef.current) {
      characterManagerRef.current.executeCharacterAction(characterId, actionName);
    }
  };
  
  // Function to execute a specific action for a vehicle
  const executeVehicleAction = (vehicleId, actionName) => {
    if (characterManagerRef.current) {
      characterManagerRef.current.executeVehicleAction(vehicleId, actionName);
    }
  };
  
  // Function to make all characters and vehicles perform random actions
  const allEntitiesRandomActions = () => {
    if (!characterManagerRef.current) return;
    
    // Random actions for regular characters
    const characterIds = characterManagerRef.current.getCharacterIds();
    characterIds.forEach(id => {
      if (id === 'worker1') {
        const actions = ['patrol', 'checkMachine1', 'salute'];
        const randomAction = actions[Math.floor(Math.random() * actions.length)];
        characterManagerRef.current.executeCharacterAction(id, randomAction);
      } else if (id === 'supervisor') {
        const actions = ['inspectMachines', 'checkMachine2'];
        const randomAction = actions[Math.floor(Math.random() * actions.length)];
        characterManagerRef.current.executeCharacterAction(id, randomAction);
      }
    });
    
    // Random actions for vehicles
    const vehicleIds = characterManagerRef.current.getVehicleIds();
    vehicleIds.forEach(id => {
      if (id === 'forklift1') {
        const actions = ['transportMaterials', 'inventoryCheck', 'serviceMachine'];
        const randomAction = actions[Math.floor(Math.random() * actions.length)];
        characterManagerRef.current.executeVehicleAction(id, randomAction);
      }
    });
  };
  
  // Create component for custom HUD controls
  const CustomHUD = () => {
    return (
      <>
        <HudControls 
          toggleAlert={toggleAlert} 
          toggleMachine={toggleMachine}
        />
        
        {/* Character control buttons */}
        <Box sx={{ position: 'absolute', bottom: 20, left: 20, zIndex: 100 }}>
          <ButtonGroup orientation="vertical" variant="contained" sx={{ mr: 2 }}>
            <Button onClick={() => executeCharacterAction('worker1', 'patrol')}>
              Worker: Patrol
            </Button>
            <Button onClick={() => executeCharacterAction('worker1', 'checkMachine1')}>
              Worker: Check Machine 1
            </Button>
            <Button onClick={() => executeCharacterAction('worker1', 'salute')}>
              Worker: Salute
            </Button>
          </ButtonGroup>
          
          <ButtonGroup orientation="vertical" variant="contained" sx={{ mr: 2 }}>
            <Button onClick={() => executeCharacterAction('supervisor', 'inspectMachines')}>
              Supervisor: Inspect Machines
            </Button>
            <Button onClick={() => executeCharacterAction('supervisor', 'checkMachine2')}>
              Supervisor: Check Machine 2
            </Button>
          </ButtonGroup>
          
          {/* Forklift control buttons */}
          <ButtonGroup orientation="vertical" variant="contained" sx={{ mr: 2 }}>
            <Button onClick={() => executeVehicleAction('forklift1', 'transportMaterials')}>
              Forklift: Transport Materials
            </Button>
            <Button onClick={() => executeVehicleAction('forklift1', 'inventoryCheck')}>
              Forklift: Inventory Check
            </Button>
            <Button onClick={() => executeVehicleAction('forklift1', 'serviceMachine')}>
              Forklift: Service Machine
            </Button>
          </ButtonGroup>
          
          <Button 
            variant="contained" 
            onClick={allEntitiesRandomActions}
            color="secondary"
          >
            Random Actions
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Box 
      sx={{ 
        width: '100vw', 
        height: '100vh', 
        position: 'absolute', 
        top: 0, 
        left: 0 
      }}
    >
      <CustomHUD />
      
      <Canvas shadows>
        {/* Camera setup */}
        <PerspectiveCamera makeDefault position={[8, 5, 8]} fov={45} />
        <OrbitControls 
          enableZoom={true} 
          enablePan={true}
          makeDefault
        />
        
        {/* Lighting */}
        <ambientLight intensity={0.6}/>
        <PointLightArray 
          startPosition={[-20, 10, 20]}
          endPosition={[20, 10, 20]}
          spacing={20}
          height={10}
          intensity={1.5}
          color="#e0e2e5"
          castShadow={true}
        />

        {/* Walls */}
        <InteriorWalls 
          paths={[
            [
              {x: -25, z: -35},
              {x: 25, z: -35},
              {x: 25, z: 35},
              {x: -25, z: 35},
              {x: -25, z: -35}
            ]
          ]}
          height={10}
          thickness={0.5}
          color="#e5e5e5"
        />
        
        {/* Ground/floor */}
        <mesh 
          position={[0, -0.25, 0]} 
          receiveShadow
        >
          <boxGeometry args={[50, 0.5, 70]} />
          <PolishedConcreteMaterial/>
        </mesh>

        {/* Yellow Lines */}
        <YellowLines paths={[
          [{x:-25, z:4}, {x:9, z:4}],
          [{x:9, z:4.15}, {x:9, z:-35}],
          [{x:16, z:35}, {x:16, z:-35}],
          [{x:-25, z:11}, {x:9, z:11}],
          [{x:9, z:10.85}, {x:9, z:35}],
        ]} lineWidth={.3} color={'#FFCC00'}/>
        {/* Blue Lines */}
        <YellowLines paths={[
          [{x:-25, z:11.9}, {x:8.85, z:11.9}],
          [{x:15.85, z:11.9}, {x:9.15, z:11.9}],
          [{x:15.85, z:3.1}, {x:9.15, z:3.1}],
          [{x:-25, z:3.1}, {x:8.85, z:3.1}],
          [{x:16.9, z:-35}, {x:16.9, z:35}],
        ]} lineWidth={1.5} color={'green'}/>
        
        <Suspense fallback={null}>
          {/* Machines */}
          <MachineGroup 
            position={[-3, 0, -3]} 
            rotation={[0, -Math.PI/4, 0]} 
            showAlert={alertActive && activeMachine === 1} 
            id="CNC-01" 
          />
          
          <MachineGroup 
            position={[4, 0, -3]} 
            rotation={[0, -Math.PI/4, 0]} 
            showAlert={alertActive && activeMachine === 2} 
            id="CNC-02" 
          />

          {/* Other models - removing standalone forklift since it's now a managed vehicle */}
          <CustomModel 
            model="/render/flag.glb" 
            position={[-5, 3.5, 30.3]} 
            rotation={[0,Math.PI,0]}
            offset={[0.5, 0, 0.5]} 
            scale={.0155}
          />

          {/* Multiple Characters with the Character Manager - now handling both characters and vehicles */}
          <CharacterManager
            ref={characterManagerRef}
            characters={characterConfigurations}
            vehicles={vehicleConfigurations}
            onActionComplete={handleActionComplete}
          />
          
          {/* Visualize waypoints */}
          {Object.entries(waypoints).map(([name, point], index) => (
            <mesh 
              key={`waypoint-${name}`} 
              position={[point.x, 0.5, point.z]}
            >
              <sphereGeometry args={[0.3, 16, 16]} />
              <meshStandardMaterial 
                color={point.color} 
                emissive={'#00ff00'}
                emissiveIntensity={0.5}
              />
            </mesh>
          ))}
        </Suspense>
      </Canvas>
    </Box>
  );
};

export default ModelScene;