import React, { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

const CustomModel = ({ 
  model, 
  position = [0, 0, 0], 
  offset = [0, 0, 0], 
  scale = 1,
  rotation = [0, 0, 0], // Added rotation parameter as Euler angles in radians
  autoRotate = false,   // Option to enable automatic rotation
  autoRotateSpeed = 0.5 // Speed of auto rotation in radians per second
}) => {
  const groupRef = useRef();
  const [modelSize, setModelSize] = useState(new THREE.Vector3(0, 0, 0));
  const [finalPosition, setFinalPosition] = useState([...position]);
  
  // Load the GLB model
  const { scene } = useGLTF(model);
  
  useEffect(() => {
    if (scene) {
      // Clone the scene to avoid modifying the cached original
      const clonedScene = scene.clone();
      
      // Apply scale to the cloned scene
      clonedScene.scale.set(scale, scale, scale);
      
      // Apply initial rotation to the cloned scene
      clonedScene.rotation.set(rotation[0], rotation[1], rotation[2]);
      
      // Calculate the bounding box to get model dimensions
      const boundingBox = new THREE.Box3().setFromObject(clonedScene);
      const size = new THREE.Vector3();
      boundingBox.getSize(size);
      setModelSize(size);
      
      // Apply shadows to all meshes in the model
      clonedScene.traverse((child) => {
        if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      
      // Clear any previous children from the group
      while (groupRef.current.children.length > 0) {
        groupRef.current.remove(groupRef.current.children[0]);
      }
      
      // Add the cloned scene to our group
      groupRef.current.add(clonedScene);
    }
  }, [scene, model, scale, rotation]);
  
  // Update position when offset or position or model size changes
  useEffect(() => {
    // Calculate final position based on model size and offset
    const newPosition = [
      position[0] + modelSize.x * offset[0],
      position[1] + modelSize.y * offset[1],
      position[2] + modelSize.z * offset[2]
    ];
    
    setFinalPosition(newPosition);
  }, [position, offset, modelSize]);
  
  // Update position of the group
  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.position.set(finalPosition[0], finalPosition[1], finalPosition[2]);
    }
  }, [finalPosition]);
  
  // Handle auto-rotation if enabled
  useFrame((state, delta) => {
    if (autoRotate && groupRef.current && groupRef.current.children[0]) {
      // Apply rotation around the Y axis (most common for objects)
      groupRef.current.children[0].rotation.y += autoRotateSpeed * delta;
    }
  });
  
  // Handle manual rotation updates
  useEffect(() => {
    if (groupRef.current && groupRef.current.children[0]) {
      groupRef.current.children[0].rotation.set(rotation[0], rotation[1], rotation[2]);
    }
  }, [rotation]);
  
  return (
    <group ref={groupRef} />
  );
};

// Pre-load the model to improve performance
const preloadModel = (modelPath) => {
  useGLTF.preload(modelPath);
};

export { CustomModel, preloadModel };