import { createContext, useReducer, useState } from "react";
import { useCollection } from "../hooks/useCollection";
import { useEffect } from "react";
import { useSources } from "../hooks/useSources";
export const AppContext = createContext();

const filterKeyToType = {
  apps: "app",
  megafactories: "megafactory",
  factories: "factory",
  videos: "video",
  beacons: "beacon",
};

const filterViews = (selectedFilter, allAssets) => {
  if (selectedFilter) {
    const filterData = selectedFilter?.filter;
    const filteredList =
      allAssets &&
      allAssets.filter((item) => {
        const filterKey = Object.keys(filterKeyToType).find(
          (key) => filterKeyToType[key] === item.Type
        );
        const filterValues = filterData[filterKey];
        if (filterValues && filterValues.includes("all")) {
          return true;
        }
        return filterValues && filterValues.includes(item.id);
      });
    return filteredList;
  }
};
const filterAssets = (searchState) => {
  const { searchTerm, allAssets, useCases, view } = searchState;
  let newFilteredAssets = allAssets;
  //handle search terms
  if (searchTerm !== "" && searchTerm !== null && searchTerm !== undefined) {
    newFilteredAssets = allAssets.filter(
      (asset) =>
        asset &&
        asset.Title &&
        searchTerm &&
        asset.Title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  } else {
    if(view){
      newFilteredAssets =
      (allAssets && filterViews(view, allAssets)) || allAssets;
    }
    else{
      newFilteredAssets = allAssets;
    }
  }
  if (useCases.length > 0) {
    newFilteredAssets = newFilteredAssets.filter(
      (asset) =>
        asset &&
        asset.tags &&
        asset.tags.some((itemTag) =>
          useCases.some((filterTag) => filterTag.id === itemTag)
        )
    );
  }
  return {
    ...searchState,
    searchTerm: searchTerm,
    filteredAssets: newFilteredAssets,
  };
};

const filterByViewAssets = (searchState) => {
  const { allAssets, view } = searchState;
  let newFilteredAssets = allAssets;
  if (view) {
   newFilteredAssets =
      (allAssets && filterViews(view, allAssets)) || allAssets;
  }
  return {
    ...searchState,
    filteredAssets: newFilteredAssets,
  };
};

const searchReducer = (searchState, searchAction) => {
  switch (searchAction.type) {
    case "INITIALIZE":
      return filterAssets({
        ...searchState,
        allAssets: searchAction.payload.assets,
      });
    case "FREE_SOLO":
      const searchTerm = searchAction.payload.searchTerm.toLowerCase();
      return filterAssets({ ...searchState, searchTerm: searchTerm});
    case "USE_CASE":
      return filterAssets({
        ...searchState,
        useCases: searchAction.payload.useCaseSelection
      });
    case "VIEW":
      return filterByViewAssets({
        ...searchState,
        view: searchAction.payload.view,
      });
    default:
      return searchState;
  }
};

const appReducer = (state, action) => {
  switch (action.type) {
    case "SELECT_NODE":
      if (
        JSON.stringify(action.payload) === JSON.stringify(state.selectedNode)
      ) {
        return { ...state, selectedNode: null };
      } else {
        return { ...state, selectedNode: action.payload };
      }
    default:
      return state;
  }
};

export function AppProvider({ children }) {
  //get all of the assets
  const [selectedOpp, setSelectedOpp] = useState(null);
  const [activeMockPc, setActiveMockPc] = useState(null);
  const [oppyState, setOppyState] = useState(false);
  const [activeFactory, setActiveFactory] = useState(null);
  const [activeMegaFactory, setActiveMegaFactory] = useState(null);
  const [activeVideo, setActiveVideo] = useState(null);
  const [register, setRegister] = useState(null);
  const [newAsset, setNewAsset] = useState(null);
  const [activeIpad, setActiveIpad] = useState(null);
  const { combinedAssets, vtecAssetsUnsorted } = useSources();
  const [vtecAssets, setVtecAssets] = useState(null);

  const themeData = localStorage.getItem("theme");
  const currentTheme = themeData
    ? JSON.parse(themeData)
    : {
        primaryColor: "#00263e",
        bgColor: "#E7E6DF",
        highlight: "#F2EEA1",
      };
  const logo = localStorage.getItem("logo");

  const [svgLogo, setSvgLogo] = useState(logo ?? null);
  const [partnerTheme, setPartnerTheme] = useState(currentTheme);

  //establish the search initial values which should be empty
  const [searchState, searchDispatch] = useReducer(searchReducer, {
    allAssets: vtecAssets,
    filteredAssets: vtecAssets,
    useCases: [],
    toggles: [],
    searchTerm: "",
  });

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [state, dispatch] = useReducer(appReducer, {
    selectedNode: null,
  });
  const { documents: useCases } = useCollection("use_cases");
  const { documents: filters } = useCollection("views");
  const [ selectedFilter, setSelectedFilter ] = useState(null);

  useEffect(() => {
    // This effect will run once during component mount
    if (!searchState.allAssets !== vtecAssets) {
      searchDispatch({ type: "INITIALIZE", payload: { assets: vtecAssets } });
    }
    //refresh the asset plz
    if (selectedAsset) {
      const updatedAsset = vtecAssets.find(
        (asset) => asset.id === selectedAsset.id
      );
      if (updatedAsset) {
        setSelectedAsset(updatedAsset);
      }
    }
  }, [vtecAssets, selectedAsset]); // Only run the effect when vtecAssets changes

  useEffect(() => {
    beginViewFilter(selectedFilter)
  }, [selectedFilter]);

  const beginSearch = (searchTerm) => {
    searchDispatch({ type: "FREE_SOLO", payload: { searchTerm: searchTerm } });
  };

  const beginUseCase = (useCaseSelection) => {
    searchDispatch({
      type: "USE_CASE",
      payload: { useCaseSelection: useCaseSelection},
    });
  };
  const beginViewFilter = (viewName) => {
    searchDispatch({ type: "VIEW", payload: { view: viewName } });
  };
  const selectNode = (selectedNode) => {
    dispatch({ type: "SELECT_NODE", payload: selectedNode });
  };

  return (
    <AppContext.Provider
      value={{
        vtecAssets,
        setVtecAssets,
        combinedAssets,
        useCases,
        selectedOpp,
        oppyState,
        setOppyState,
        setSelectedOpp,
        newAsset,
        setNewAsset,
        beginSearch,
        activeFactory,
        setActiveFactory,
        activeVideo,
        setActiveVideo,
        beginUseCase,
        beginViewFilter,
        searchState,
        vtecAssetsUnsorted,
        ...state,
        svgLogo,
        setSvgLogo,
        dispatch,
        register,
        setRegister,
        selectedAsset,
        setSelectedAsset,
        activeMockPc,
        setActiveMockPc,
        activeIpad,
        setActiveIpad,
        activeMegaFactory,
        setActiveMegaFactory,
        selectNode,
        partnerTheme,
        setPartnerTheme,
        setSelectedFilter,
        selectedFilter,
        filters,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
