import Grid from "@mui/material/Grid";
import { useApp } from "../../hooks/useApp";
import { useSources } from "../../hooks/useSources";
import { Box, Stack, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { TextField } from "@mui/material";
import { useDocs } from "../../hooks/useDocs";

export default function Admin() {
  const {
    searchState,
    vtecAssets,
    selectedAsset,
    selectedOpp,
    oppyState,
    setOppyState,
  } = useApp();
  const { updateAsset, deleteAsset } = useDocs("vtec_assets");
  const { reviewAssets } = useSources();
  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedReview, setSelectedReview] = useState({});

  const columns = [
    { field: "id", headerName: "ID", width: 200 },
    { field: "Title", headerName: "Title", width: 260 },
    { field: "Heading", headerName: "Heading", width: 130 },
    { field: "Type", headerName: "Type", width: 130 },
    { field: "email", headerName: "Email", width: 260 },
  ];

  const adminAssets = reviewAssets
    ? reviewAssets.map((asset) => {
        return {
          id: asset.id,
          Heading: asset.Heading,
          Title: asset.Title,
          Image: asset.Image,
          img: asset.img,
          Type: asset.Type,
          desc: asset.desc,
          contentUrl: asset.contentUrl,
          video: asset.video,
          email: asset.email,
        };
      })
    : [{ id: "loading" }];

  const handleCellClick = (params, event) => {
    const filteredAssets = reviewAssets.filter(
      (asset) => asset.id === params.row.id
    );
    if (filteredAssets.length > 0) {
      setSelectedRow(filteredAssets[0]);
    } else {
      setSelectedRow(null);
    }
  };

  const handleChange = (key, value) => {
    setSelectedRow((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSave = () => {
    updateAsset(selectedRow);
  };

  const handleDelete = () => {
    deleteAsset(selectedRow);
    setSelectedRow(null);
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      height={"100vh"}
      alignItems={"center"}
      gap={2}
      margin={2}
    >
      <Grid item xs={7} width={"500px"} backgroundColor={"white"} padding={2}>
        <Typography marginY={2}>Assets to Review</Typography>
        <DataGrid
          rows={adminAssets}
          columns={columns}
          onCellClick={handleCellClick}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20, 40]}
          checkboxSelection={false}
        />
      </Grid>
      <Grid item xs backgroundColor={"white"} padding={2}>
        <Stack>
          <Typography marginY={2}>Selected Asset</Typography>
          <Box height={"600px"} overflow={"scroll"}>
            {selectedRow &&
              Object.entries(selectedRow).map(([key, value]) =>
                Array.isArray(value) ? null : (
                  <TextField
                    key={key}
                    label={key}
                    value={value || ""}
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="normal"
                    disabled={key == "id" ? true : false}
                    onChange={(e) => handleChange(key, e.target.value)}
                  />
                )
              )}
          </Box>
          <Stack direction={"row"} spacing={2}>
            <Button color="success" variant="contained" onClick={handleSave}>
              Save
            </Button>
            <Button color="error" variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
