import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase/config";
import Create from "./pages/create/Create";
import Login from "./pages/login/Login";
import Vtec from "./pages/vtec/Vtec";
import Admin from "./pages/admin/Admin";
import PartnerLogin from "./pages/partner/PartnerLogin";
import Composable from "./pages/composable/Composable";
import FactoryDirect from "./pages/factorydirect/FactoryDirect";
import CommonDataModel from "./pages/commondatamodel/CommonDataModel";
import PartnerAdmin from "./pages/partneradmin/PartnerAdmin";
import { useApp } from "./hooks/useApp";
import { ThemeProvider } from "@mui/material";
import Assets from "./pages/assets/Assets";
import FactoryCreate from "./pages/factoryCreate/FactoryCreate";
import createCustomTheme from "./components/theme/theme";
import PrivateAccounts from "./pages/privateAccounts/PrivateAccounts";
import Toast from "./components/Common/Toast";
import Flow from "./pages/flows/Flow";
import Uns from "./pages/uns/Uns";
import ManageFilters from "./pages/filters/ManageFilters";
import Seedvault from "./pages/seedvault/Seedvault";
import Paste from "./pages/seedvault/Paste";
import Copy from "./pages/seedvault/Copy";
import Proveit from "./pages/proveit/Proveit";
import AssetPage from "./pages/assetPage/AssetPage";
import ModelScene from "./pages/render/ModelScene";
function App() {
  const { authIsReady, user } = useAuth();
  const {
    setVtecAssets,
    combinedAssets,
    partnerTheme,
    setPartnerTheme,
    setSvgLogo,
    setSelectedFilter, 
    filters
  } = useApp();

  // useEffect to re-render when 'user' changes
  useEffect(() => {
    if (user) {
      httpsCallable(functions, "identify")().then((result) => {});
      if (user.privateAccount) {
        const currentTheme = localStorage.getItem("theme");
        const logo = localStorage.getItem("logo");
        if (!currentTheme || !logo) {
          if (user.privateAccount.theme) {
            setPartnerTheme(user.privateAccount.theme);
            localStorage.setItem(
              "theme",
              JSON.stringify(user.privateAccount.theme)
            );
          }
          if (user.privateAccount.svg) {
            setSvgLogo(user.privateAccount.svg);
            localStorage.setItem("logo", user.privateAccount.svg);
          }
        }
      }
    }
  }, [user]);
  useEffect(() => {
      if (filters) {
        if (user?.defaultView) {
          const defaultView = filters.find((x) => x.id === user.defaultView);
          setSelectedFilter(defaultView);
        } else {
          const defaultView = filters.find((x) => x.isAdminDefault === true);
          setSelectedFilter(defaultView);
        }
      }
    }, [filters, user]);
  const sorter = (assets) => {
    return assets
      ? assets.sort((a, b) => {
          const aIsPrivate = a.privateAccount === user.domain;
          const bIsPrivate = b.privateAccount === user.domain;
          if (aIsPrivate !== bIsPrivate) {
            return bIsPrivate - aIsPrivate; 
          }
        })
      : null;
  };
  useEffect(() => {
    if (user && combinedAssets) {
      if (user?.admin === true) {
        setVtecAssets(combinedAssets);
      } else {
        if (user.domain) {
          
          const assetsAfterFilter = combinedAssets.filter(
            (asset) =>
              !asset.private || user.domain === asset.private
          );
          const sortedAssets = sorter(assetsAfterFilter);
          setVtecAssets(sortedAssets);
        } else {
          setVtecAssets(
            combinedAssets.filter((asset) => !asset.privateAccount)
          );
        }
      }
    }
  }, [user, combinedAssets]);

  let theme = createCustomTheme(
    partnerTheme.primaryColor,
    partnerTheme.bgColor,
    partnerTheme.highlight
  );
  useEffect(() => {
    theme = createCustomTheme(
      partnerTheme.primaryColor,
      partnerTheme.bgColor,
      partnerTheme.highlight
    );
    const root = document.documentElement;
    root?.style.setProperty(
      "--gradient-default-nav",
      `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    );
    root?.style.setProperty(
      "--gradient-default",
      `linear-gradient(to left,${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    );
    root?.style.setProperty(
      "--gradient-default-bottom",
      `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    );
    root?.style.setProperty(
      "--radial-gradient",
      `radial-gradient(circle at 50% 50%, ${theme.palette.secondary.main} 0%, transparent 90% ), ${theme.palette.primary.main}`
    );
    root?.style.setProperty(
      "--text-white",
      `${theme.palette.customColors.white}`
    );
  }, [partnerTheme]);
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: "background.default",
          minHeight: "100vh",
        }}
      >
        {authIsReady && (
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  user && user.approved ? (
                    <Vtec />
                  ) : user ? (
                    <Navigate to="/login" replace={true} />
                  ) : (
                    <Navigate to="/login" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/login"
                element={
                  !user || (user && !user.approved) ? (
                    <Login />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/seedvault"
                element={user?.admin ? <Seedvault /> : <Copy />}
              ></Route>
              <Route
                exact
                path="/assets"
                element={user && user.approved ? <Assets /> : <Navigate to="/" replace={true} />}
              ></Route>
              <Route
                exact
                path="/admin"
                element={
                  user?.admin ? (
                    <Admin />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/users"
                element={
                  user?.admin ? (
                    <PartnerAdmin />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/partnerlogin"
                element={
                  !user ? <PartnerLogin /> : <Navigate to="/" replace={true} />
                }
              ></Route>
              <Route path="/composable" element={<Composable />}></Route>
              <Route path="/composable/:param" element={<Composable />}></Route>
              <Route
                path="/factory/:account/:asset"
                element={<FactoryDirect />}
              ></Route>
              <Route
                exact
                path="/commondatamodel"
                element={<CommonDataModel />}
              ></Route>
              <Route
                exact
                path="/create"
                element={user && user.approved ? <Create /> : <Navigate to="/" replace={true} />}
              ></Route>
              <Route
                exact
                path="/factorycreate"
                element={
                  user && user.approved ? (
                    <FactoryCreate isEdit={false} />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/factoryedit"
                element={
                  user && user.approved ? (
                    <FactoryCreate isEdit={true} />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/private-accounts"
                element={
                  user && user.approved && user.domain ? (
                    <PrivateAccounts />
                  ) : (
                    <Navigate to="/" replace={true} />
                  )
                }
              ></Route>
              <Route
                exact
                path="/uns"
                element={
                    <Uns/>
                }
              ></Route>
              <Route
                exact
                path="/flows/:flowId"
                element={user ? <Flow /> : <Navigate to="/" replace={true} />}
              ></Route>
              <Route
                exact
                path="/manage-views"
                element={
                  user ? <ManageFilters /> : <Navigate to="/" replace={true} />
                }
              ></Route>
              <Route
                exact
                path="/asset/:account/:assetId"
                element={
                  user ? <AssetPage /> : <Navigate to="/" replace={true} />
                }
              ></Route>
              <Route
                exact
                path="/proveit"
                element={
                    <Proveit/>
                }
              ></Route>
              <Route
                exact
                path="/modelscene"
                element={
                    <ModelScene/>
                }
              ></Route>
            </Routes>
          </BrowserRouter>
        )}
      </Box>
      <Toast />
    </ThemeProvider>
  );
}

export default App;
