import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid } from "@mui/material";
import { useApp } from "../hooks/useApp";
import { useState, useMemo } from "react";
import debounce from "lodash.debounce";

export default function Freesolo() {
  const { vtecAssets, beginSearch } = useApp();
  const [searchText, setSearchText] = useState("");

  // Sort options only when vtecAssets changes
  const sortedOptions = useMemo(() => {
    return vtecAssets
      ? vtecAssets
          .slice()
          .sort((a, b) => a.Title.localeCompare(b.Title))
          .map((option) => option.Title).filter((value, index, self) => self.indexOf(value)=== index)
      : ["Loading..."];
  }, [vtecAssets]);

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        beginSearch(value);
      }, 300), // Adjust debounce time as needed
    [beginSearch]
  );

  const handleInputChange = (event, newValue) => {
    setSearchText(newValue);
    debouncedSearch(newValue);
  };
  
  return (
    <Grid item>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        sx={{ backgroundColor: "#FFF" }}
        disableClearable
        onInputChange={handleInputChange}
        options={sortedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for examples"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
    </Grid>
  );
}
