import React, { useMemo } from 'react';
import * as THREE from 'three';

// Component to create interior walls from a set of points
const InteriorWalls = ({ 
  paths = [], 
  height = 10,
  thickness = 0.5,
  color = "#e0e0e0",
  roughness = 0.8,
  metalness = 0,
  baseOffset = 0, // Height offset from the ground
  textureRepeat = 2 // How many times to repeat the texture along the wall height
}) => {
  // Create a procedural wall texture - always create it regardless of paths validity
  const wallTexture = useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 512;
    canvas.height = 512;
    const context = canvas.getContext('2d');
    
    // Base wall color
    context.fillStyle = '#e0e0e0';
    context.fillRect(0, 0, canvas.width, canvas.height);
    
    // Add some subtle texture to the wall
    for (let i = 0; i < 5000; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const size = Math.random() * 2;
      const gray = 220 + Math.random() * 25;
      context.fillStyle = `rgb(${gray}, ${gray}, ${gray})`;
      context.fillRect(x, y, size, size);
    }
    
    // Add subtle lines to look like wall panels (horizontal)
    context.strokeStyle = '#d0d0d0';
    context.lineWidth = 1;
    for (let y = 0; y < canvas.height; y += 128) {
      context.beginPath();
      context.moveTo(0, y);
      context.lineTo(canvas.width, y);
      context.stroke();
    }
    
    // Add subtle vertical lines every 256 pixels
    for (let x = 0; x < canvas.width; x += 256) {
      context.beginPath();
      context.moveTo(x, 0);
      context.lineTo(x, canvas.height);
      context.stroke();
    }
    
    const texture = new THREE.CanvasTexture(canvas);
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    return texture;
  }, []);
  
  // Validate paths input
  if (!Array.isArray(paths) || paths.length === 0) {
    console.warn("InteriorWalls component requires at least one path array");
    return null;
  }
  
  return (
    <group>
      {paths.map((path, pathIndex) => (
        path.length >= 2 && (
          <group key={`wall-path-${pathIndex}`}>
            {path.map((_, i) => {
              // Need at least two points to make a wall segment
              if (i < path.length - 1) {
                const startPoint = path[i];
                const endPoint = path[i+1];
                
                // Calculate wall properties
                const dx = endPoint.x - startPoint.x;
                const dz = endPoint.z - startPoint.z;
                const wallLength = Math.sqrt(dx * dx + dz * dz);
                const angle = Math.atan2(dz, dx);
                
                // Position is the midpoint of the wall
                const xPos = (startPoint.x + endPoint.x) / 2;
                const zPos = (startPoint.z + endPoint.z) / 2;
                
                return (
                  <mesh 
                    key={`wall-segment-${pathIndex}-${i}`}
                    position={[xPos, baseOffset + height / 2, zPos]}
                    rotation={[0, angle, 0]}
                    castShadow
                    receiveShadow
                  >
                    <boxGeometry args={[wallLength, height, thickness]} />
                    <meshStandardMaterial 
                      color={color}
                      roughness={roughness}
                      metalness={metalness}
                      map={wallTexture}
                      side={THREE.DoubleSide} // Render both sides of the wall
                      onUpdate={(self) => {
                        // Set texture repeat for proper scaling
                        if (self.map) {
                          self.map.repeat.set(wallLength / 4, textureRepeat);
                        }
                      }}
                    />
                  </mesh>
                );
              }
              return null;
            })}
          </group>
        )
      ))}
    </group>
  );
};

// Component to create a complete room with walls, floor, and ceiling
const WarehouseRoom = ({
  width = 100,
  length = 100,
  height = 10,
  wallColor = "#e0e0e0",
  floorColor = "#d0d0d0",
  ceilingColor = "#f5f5f5",
  wallThickness = 0.5,
  includeFloor = true,
  includeCeiling = true
}) => {
  // Create the four walls as a single path
  const wallPaths = useMemo(() => {
    const halfWidth = width / 2;
    const halfLength = length / 2;
    
    // Define paths for four walls in a rectangle
    return [
      [
        { x: -halfWidth, z: -halfLength },
        { x: halfWidth, z: -halfLength },
        { x: halfWidth, z: halfLength },
        { x: -halfWidth, z: halfLength },
        { x: -halfWidth, z: -halfLength } // Close the loop
      ]
    ];
  }, [width, length]);
  
  return (
    <group>
      {/* Walls */}
      <InteriorWalls 
        paths={wallPaths}
        height={height}
        thickness={wallThickness}
        color={wallColor}
      />
      
      {/* Floor */}
      {includeFloor && (
        <mesh position={[0, -0.25, 0]} rotation={[-Math.PI / 2, 0, 0]} receiveShadow>
          <planeGeometry args={[width, length]} />
          <meshStandardMaterial color={floorColor} roughness={0.8} />
        </mesh>
      )}
      
      {/* Ceiling */}
      {includeCeiling && (
        <mesh position={[0, height, 0]} rotation={[Math.PI / 2, 0, 0]} receiveShadow>
          <planeGeometry args={[width, length]} />
          <meshStandardMaterial color={ceilingColor} roughness={0.9} />
        </mesh>
      )}
    </group>
  );
};

// Export both components
export { InteriorWalls, WarehouseRoom };

// Default export
const Walls = {
  InteriorWalls,
  WarehouseRoom
};

export default Walls;