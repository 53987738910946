import AssetCard from "../../components/Assetcard";
import {
  Grid,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  Button,
  IconButton,
} from "@mui/material";
import { useApp } from "../../hooks/useApp";
import DeleteIcon from "@mui/icons-material/Delete";
import { emitToast } from "../../common/toastEmitter";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ImageUpload from "../../components/ImageUpload";

export default function FactoryTwo({ asset, setAsset }) {
  const { combinedAssets } = useApp();
  function handleChange(event) {
    const newAsset = { ...asset, [event.target.id]: event.target.value };
    setAsset(newAsset);
  }
  const handleAddMore = () => {
    const count = asset.suites.length; // or another method to generate unique index
    const newChild = {
      sortOrder: count + 1,
      factoryId: "",
      color: "",
      textHeader: "",
      text: "",
    };
    const newChildren = [...asset.suites, newChild];
    setAsset((prevAsset) => ({ ...prevAsset, suites: newChildren }));
  };
  const handleRemove = (factoryToRemove) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      suites: prevAsset.suites.filter((factory) => factory !== factoryToRemove),
    }));
  };
  const handleFactoryChange = (index, newValue) => {
    const factoryId = newValue ? newValue.id : "";

    if (asset.suites.some((child) => child.factoryId === factoryId)) {
      emitToast("Already Selected factory, Please choose another", "error");
      setAsset((prevAsset) => {
        const newSuites = [...prevAsset.suites];
        newSuites[index] = { ...newSuites[index], factoryId: null };
        return { ...prevAsset, suites: newSuites };
      });
      return;
    }

    setAsset((prevAsset) => {
      const newSuites = [...prevAsset.suites];
      newSuites[index] = { ...newSuites[index], factoryId };
      return { ...prevAsset, suites: newSuites };
    });
  };

  const handleColorChange = (index, newColor) => {
    setAsset((prevAsset) => {
      const newSuites = [...prevAsset.suites];
      newSuites[index] = { ...newSuites[index], color: newColor };
      return { ...prevAsset, suites: newSuites };
    });
  };
  const handleTextChange = (event, index) => {
    setAsset((prevAsset) => {
      const newSuites = [...prevAsset.suites];
      newSuites[index] = {
        ...newSuites[index],
        [event.target.name]: event.target.value,
      };
      return { ...prevAsset, suites: newSuites };
    });
  };
  const getFactoryOptions = () =>
    combinedAssets.filter((asset) => asset.Type === "factory");
  const getSelectedFactory = (id) =>
    combinedAssets.find((ex) => ex.id === id || null);
  const handleFileUploadChange = (file) => {
      const newAsset = { ...asset, Image: file };
      setAsset(newAsset);
  };

  return (
    <Grid container sx={{ flexDirection: "row", gap: 4 }}>
      <Grid
        item
        xs={12}
        md={5}
        sx={{ justifyContent: "center", alignItems: "center" }}
      >
        <AssetCard asset={asset} />
      </Grid>
      <Grid item xs>
        <Stack direction={"column"} gap={2}>
          
          <TextField
            id="Title"
            label="Title"
            fullWidth
            value={asset.Title}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <TextField
            id="Heading"
            label="Heading"
            fullWidth
            value={asset.Heading}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <TextField
            id="private"
            label="Private Asset ID"
            fullWidth
            value={asset.private}
            onChange={(event) => {
              handleChange(event);
            }}
          />
          <ImageUpload value={asset.Image} onChange={handleFileUploadChange} />
        </Stack>
        {asset.Type === "megafactory" && (
          <Stack direction={"column"} gap={2}>
            <Typography variant="h6">Add Suites</Typography>
            {asset.suites &&
              asset.suites.map((factory, index) => (
                <Stack
                  key={index}
                  gap={2}
                  border={1}
                  padding={2}
                  borderColor={"primary.main"}
                  borderRadius={1}
                >
                  {combinedAssets && (
                    <Stack direction={"row"} spacing={2}>
                      <Autocomplete
                        disablePortal
                        id="factorySelect"
                        options={getFactoryOptions()}
                        getOptionLabel={(option) => option.Title} // Specify which property to use as the label
                        sx={{ width: "100%" }}
                        size="small"
                        value={getSelectedFactory(factory.factoryId)}
                        onChange={(event, newValue) => {
                          handleFactoryChange(index, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Title" />
                        )}
                      />
                      {index !== 0 && (
                        <IconButton
                          onClick={() => {
                            handleRemove(factory);
                          }}
                        >
                          <DeleteIcon></DeleteIcon>
                        </IconButton>
                      )}
                    </Stack>
                  )}
                  <TextField
                    id="color"
                    label="Color"
                    fullWidth
                    value={factory.color}
                    size="small"
                    onChange={(e) => handleColorChange(index, e.target.value)}
                  />
                  <TextField
                    id="text1"
                    label="Text Header"
                    fullWidth
                    value={factory.textHeader}
                    size="small"
                    name="textHeader"
                    onChange={(event) => handleTextChange(event, index)}
                  />
                  <TextField
                    id="text2"
                    label="Text"
                    fullWidth
                    value={factory.text}
                    name="text"
                    size="small"
                    onChange={(event) => handleTextChange(event, index)}
                  />
                </Stack>
              ))}
            {asset.suites && asset.suites.length < 4 && (
              <Button
                variant="outlined"
                endIcon={<AddCircleRoundedIcon />}
                onClick={() => {
                  handleAddMore();
                }}
              >
                Add More
              </Button>
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
}
