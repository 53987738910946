import React, { useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

// Enhanced Character component with configurable actions
const Character = ({ 
  id, // Unique identifier for the character
  position = [0, 0, 0], 
  offset = [0, 0, 0], 
  animationStates, // Object mapping state names to animation file paths
  defaultState = 'idle', // Default animation state
  scale = 0.01,
  actions = {}, // Object containing predefined actions (sequences of movements and animations)
  onActionComplete = () => {}, // Callback when an action is completed
}, ref) => {
  const group = useRef();
  const mixer = useRef();
  const activeAction = useRef();
  const animationsRef = useRef({});
  const modelRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const [animationState, setAnimationState] = useState(defaultState);
  const fbxLoader = useRef(new FBXLoader());
  
  // Movement state
  const characterPosition = useRef(new THREE.Vector3(...position));
  const targetPosition = useRef(null);
  const isMoving = useRef(false);
  const rotationSpeed = 0.1;
  const movementSpeed = 0.05;
  
  // Action queue and current action tracking
  const actionQueue = useRef([]);
  const currentAction = useRef(null);
  const actionStep = useRef(0);
  const isExecutingAction = useRef(false);
  
  // Update animation state when prop changes
  useEffect(() => {
    if (isLoaded) {
      setAnimationState(animationState);
    }
  }, [defaultState]);
  
  // Load the initial model and set up animations
  useEffect(() => {
    // Only run this once
    if (isLoaded) return;
    
    // Start by loading the initial animation (default state)
    const initialPath = animationStates[defaultState];
    if (!initialPath) {
      console.error(`No path found for initial state: ${defaultState}`);
      return;
    }
    
    // Load the initial animation model
    fbxLoader.current.load(
      initialPath,
      (fbxModel) => {
        // Store the model
        modelRef.current = fbxModel;
        
        // Set up the mixer
        mixer.current = new THREE.AnimationMixer(fbxModel);
        
        // Configure shadows for all meshes in the model
        fbxModel.traverse((child) => {
          if (child.isMesh) {
            // Enable shadow casting on all meshes
            child.castShadow = true;
            child.receiveShadow = true;
            
            if (child.material) {
              if (Array.isArray(child.material)) {
                child.material.forEach(mat => {
                  mat.shadowSide = THREE.FrontSide;
                });
              } else {
                child.material.shadowSide = THREE.FrontSide;
              }
            }
          }
        });
        
        // Add to the scene
        if (group.current) {
          group.current.clear();
          group.current.add(fbxModel);
          
          // Position and scale
          fbxModel.position.set(offset[0], offset[1], offset[2]);
          fbxModel.scale.set(scale, scale, scale);
        }
        
        // Load all animations and create actions
        loadAllAnimations();
      },
      (xhr) => {
        console.log(`${id}: ${defaultState} ${Math.round((xhr.loaded / xhr.total) * 100)}% loaded`);
      },
      (error) => {
        console.error(`${id}: Error loading model:`, error);
      }
    );
    
    // Function to load all animations
    const loadAllAnimations = () => {
      let animationsToLoad = Object.entries(animationStates).filter(([state]) => state !== defaultState);
      let loadedCount = 0;
      
      // First set up the initial animation action
      const initialClip = modelRef.current.animations[0].clone();
      initialClip.name = defaultState;
      const initialAction = mixer.current.clipAction(initialClip);
      animationsRef.current[defaultState] = initialAction;
      initialAction.play();
      activeAction.current = initialAction;
      
      // If there are no other animations to load, we're done
      if (animationsToLoad.length === 0) {
        setIsLoaded(true);
        return;
      }
      
      // Now load the rest of the animations
      animationsToLoad.forEach(([state, path]) => {
        fbxLoader.current.load(
          path,
          (animationFbx) => {
            // Get the animation from the loaded FBX and add it to our actions
            if (animationFbx.animations && animationFbx.animations.length > 0) {
              const clip = animationFbx.animations[0].clone();
              clip.name = state;
              const action = mixer.current.clipAction(clip);
              animationsRef.current[state] = action;
              
              // Check if all animations are loaded
              loadedCount++;
              if (loadedCount >= animationsToLoad.length) {
                setIsLoaded(true);
              }
            }
          },
          (xhr) => {
            console.log(`${id}: ${state} ${Math.round((xhr.loaded / xhr.total) * 100)}% loaded`);
          },
          (error) => {
            console.error(`${id}: Error loading animation ${state}:`, error);
            // Still increment loaded count even on error to avoid getting stuck
            loadedCount++;
            if (loadedCount >= animationsToLoad.length) {
              setIsLoaded(true);
            }
          }
        );
      });
    };
    
    return () => {
      if (mixer.current) mixer.current.stopAllAction();
    };
  }, [animationStates, defaultState, id, offset, scale]);

  // Handle animation state changes
  useEffect(() => {
    if (!isLoaded || !mixer.current) return;
    
    // Get the animation for the current state
    const nextAction = animationsRef.current[animationState];
    if (!nextAction) {
      console.error(`${id}: No animation found for state: ${animationState}`);
      return;
    }
    
    // If we already have an active action, crossfade to the new one
    if (activeAction.current && activeAction.current !== nextAction) {
      activeAction.current.fadeOut(0.5);
      nextAction.reset().fadeIn(0.5).play();
      activeAction.current = nextAction;
    } else if (!activeAction.current) {
      // If no active action, just play the new one
      nextAction.reset().play();
      activeAction.current = nextAction;
    }
  }, [animationState, isLoaded, id]);

  // Method to execute a step in the current action
  const executeActionStep = () => {
    if (!currentAction.current || actionStep.current >= currentAction.current.steps.length) {
      // Action complete, return to default state
      resetToDefaultState();
      return;
    }
    
    const step = currentAction.current.steps[actionStep.current];
    
    // Execute the step based on its type
    if (step.type === 'move') {
      // Move to position
      walkTo(step.target);
      // No need to increment step here - it will be incremented when movement completes
    } else if (step.type === 'animate') {
      // Play animation for specified duration
      setAnimationState(step.animation);
      
      // Set timeout to move to next step after duration
      if (step.duration) {
        setTimeout(() => {
          actionStep.current++;
          executeActionStep();
        }, step.duration);
      } else {
        // If no duration specified, move to next step immediately
        actionStep.current++;
        executeActionStep();
      }
    } else if (step.type === 'wait') {
      // Just wait for specified duration
      setTimeout(() => {
        actionStep.current++;
        executeActionStep();
      }, step.duration || 1000);
    }
  };
  
  // Reset to default state when action is complete
  const resetToDefaultState = () => {
    isExecutingAction.current = false;
    setAnimationState(defaultState);
    
    // Call the onActionComplete callback
    onActionComplete(id, currentAction.current?.name);
    
    // Check if there are more actions in the queue
    if (actionQueue.current.length > 0) {
      // Get the next action from the queue and execute it
      const nextAction = actionQueue.current.shift();
      executeAction(nextAction);
    } else {
      currentAction.current = null;
    }
  };
  
  // Queue an action for execution
  const queueAction = (actionName) => {
    const action = actions[actionName];
    if (!action) {
      console.error(`${id}: No action found with name: ${actionName}`);
      return;
    }
    
    actionQueue.current.push({...action, name: actionName});
    
    // If not currently executing an action, start this one
    if (!isExecutingAction.current) {
      const nextAction = actionQueue.current.shift();
      executeAction(nextAction);
    }
  };
  
  // Execute a specific action
  const executeAction = (action) => {
    if (!action) return;
    
    console.log(`${id}: Executing action: ${action.name}`);
    
    // Set current action and reset step counter
    currentAction.current = action;
    actionStep.current = 0;
    isExecutingAction.current = true;
    
    // Begin executing steps
    executeActionStep();
  };

  // Update position and rotation of character
  useFrame((_, delta) => {
    // Update animation mixer
    if (mixer.current) {
      mixer.current.update(delta);
    }
    
    // Skip if not moving or no target
    if (!isMoving.current || !targetPosition.current) return;
    
    // Calculate direction to target
    const direction = new THREE.Vector3(
      targetPosition.current.x - characterPosition.current.x,
      0,
      targetPosition.current.z - characterPosition.current.z
    );
    
    // Calculate distance to target
    const distance = direction.length();
    
    // If we're close enough to the target, stop moving
    if (distance < 0.1) {
      isMoving.current = false;
      
      // If executing an action, move to next step
      if (isExecutingAction.current) {
        actionStep.current++;
        executeActionStep();
      } else {
        setAnimationState(defaultState);
      }
      return;
    }
    
    // Normalize the direction
    direction.normalize();
    
    // Calculate the target rotation
    const targetRotation = Math.atan2(direction.x, direction.z);
    
    // Get current rotation
    const currentRotation = group.current.rotation.y;
    
    // Smoothly rotate towards the target rotation
    let newRotation = currentRotation;
    
    // Find the shortest rotation direction
    const rotationDiff = targetRotation - currentRotation;
    if (rotationDiff > Math.PI) {
      newRotation += (rotationDiff - 2 * Math.PI) * rotationSpeed;
    } else if (rotationDiff < -Math.PI) {
      newRotation += (rotationDiff + 2 * Math.PI) * rotationSpeed;
    } else {
      newRotation += rotationDiff * rotationSpeed;
    }
    
    // Apply rotation
    group.current.rotation.y = newRotation;
    
    // Move in the direction we're facing
    const moveDistance = movementSpeed * delta * 60; // Normalize by framerate
    const movement = direction.multiplyScalar(moveDistance);
    
    characterPosition.current.add(movement);
    group.current.position.set(
      characterPosition.current.x,
      characterPosition.current.y,
      characterPosition.current.z
    );
  });
  
  // Simple movement methods
  const walkTo = (target) => {
    targetPosition.current = new THREE.Vector3(target.x, target.y || 0, target.z);
    isMoving.current = true;
    setAnimationState('walking');
  };
  
  const stopWalking = () => {
    isMoving.current = false;
    setAnimationState(defaultState);
  };
  
  const getCurrentPosition = () => {
    return characterPosition.current.clone();
  };
  
  const setAnimationStateManually = (state) => {
    if (animationStates[state]) {
      setAnimationState(state);
    } else {
      console.error(`${id}: No animation state found: ${state}`);
    }
  };

  // Expose methods to parent component
  React.useImperativeHandle(ref, () => ({
    walkTo,
    stopWalking,
    getCurrentPosition,
    isMoving: () => isMoving.current,
    queueAction,
    executeAction: (actionName) => {
      const action = actions[actionName];
      if (action) {
        executeAction({...action, name: actionName});
      }
    },
    setAnimationState: setAnimationStateManually,
    getId: () => id,
    isExecutingAction: () => isExecutingAction.current,
    getCurrentAction: () => currentAction.current?.name,
  }));

  return (
    <group ref={group} position={position} />
  );
};

export default React.forwardRef(Character);