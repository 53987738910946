import { useEffect } from 'react';

// Utility to handle copy event with custom MIME types
function copyCutHandler(handler) {
  return (evt) => {
    const data = handler(evt);
    if (data != null) {
      const clipboardData = evt.clipboardData;
      if (clipboardData != null) {
        Object.keys(data).forEach((mimeType) => {
          const value = data[mimeType];
          if (value != null) {
            if (typeof value === 'string') {
              clipboardData.setData(mimeType, value);
            } else {
              throw new Error('Copying files to the clipboard is not supported');
            }
          }
        });
      }
      evt.preventDefault();
    }
  };
}

// Custom hook to handle copying arbitrary data to the clipboard
export const useCopy = (dataToCopy) => {
    console.log(dataToCopy)
  useEffect(() => {
    // Register the custom copy handler when the component mounts
    const handleCopy = copyCutHandler(() => {
      // Format your passed data and return it
      return {
        "application/x.tulip.clipboard.step-editor+json": JSON.stringify(dataToCopy),
        "image/svg+xml": '',  // Example SVG
        "image/gif": '',      // Placeholder for empty GIF data
        "image/jpeg": '',     // Placeholder for empty JPEG data
        "Files": ''           // Placeholder for file data if needed
      };
    });

    // Add event listener for copy events
    document.addEventListener('copy', handleCopy);

    // Cleanup listener on unmount
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, [dataToCopy]);

  const triggerCopy = () => {
    // Programmatically trigger the copy event
    document.execCommand('copy');
  };

  return { triggerCopy };
};