import React, { useMemo } from 'react';
import * as THREE from 'three';
// Remove the problematic import and simplify the code to not require it

// Procedural Concrete Material Component
const PolishedConcreteMaterial = () => {
  const concreteBaseTexture = useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 1024;
    canvas.height = 1024;
    const context = canvas.getContext('2d');
    
    // Base color
    context.fillStyle = '#717171';
    context.fillRect(0, 0, canvas.width, canvas.height);
    
    // Add some noise for concrete texture
    for (let i = 0; i < 100000; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const gray = Math.floor(110 + Math.random() * 35); // Light gray variation
      context.fillStyle = `rgb(${gray}, ${gray}, ${gray})`;
      context.fillRect(x, y, 1, 1);
    }
    
    const texture = new THREE.CanvasTexture(canvas);
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set(5, 5); // Adjust based on floor size
    return texture;
  }, []);

  return (
    <meshPhysicalMaterial
      map={concreteBaseTexture}
      roughness={0.5}
      metalness={0.1}
      clearcoat={0.5} // This gives the polished look
      clearcoatRoughness={0.2}
      reflectivity={0.5}
    />
  );
};

// Yellow Industrial Lines Component - simplified version without BufferGeometryUtils
const YellowLines = ({ paths = [], lineWidth = 1, height = 0.001 , color='#FFCC00'}) => {
  // Validate paths input
  if (!Array.isArray(paths) || paths.length === 0) {
    console.warn("YellowLines component requires at least one path in paths array");
    return null;
  }

  return (
    <group position={[0, .01, 0]}>
      {paths.map((path, pathIndex) => (
        path.length >= 2 && (
          <group key={`path-${pathIndex}`}>
            {path.map((_, i) => (
              i < path.length - 1 && (
                <mesh 
                receiveShadow
                  key={`segment-${pathIndex}-${i}`} 
                  position={[
                    (path[i].x + path[i+1].x) / 2,
                    0, 
                    (path[i].z + path[i+1].z) / 2
                  ]}
                  rotation={[
                    -Math.PI / 2, 
                    0, 
                    Math.atan2(path[i+1].z - path[i].z, path[i+1].x - path[i].x)
                  ]}
                >
                  <planeGeometry 
                    args={[
                      Math.sqrt(
                        Math.pow(path[i+1].x - path[i].x, 2) + 
                        Math.pow(path[i+1].z - path[i].z, 2)
                      ),
                      lineWidth
                    ]} 
                  />
                  <meshStandardMaterial color={color} roughness={0.6} />
                </mesh>
              )
            ))}
          </group>
        )
      ))}
    </group>
  );
};

// Export both components together in a single object
// This allows importing like: import { PolishedConcreteMaterial, YellowLines } from './Concrete'
export { PolishedConcreteMaterial, YellowLines };

