import { useApp } from "../../hooks/useApp";
import IconButton from "@mui/material/IconButton";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CloseIcon from "@mui/icons-material/Close";
import LaunchIcon from "@mui/icons-material/Launch";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import Lottie from "lottie-react";
import touchy from "../../svgs/touchy.json";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

export default function AssetPanel({
  selectedAsset,
  setSelectedAsset,
  discover,
  youarehere,
  discoverHeader,
}) {
  const theme = useTheme();
  const { activeVideo, setActiveVideo } = useApp();
  const { activeIpad, setActiveIpad, setActiveMockPc } = useApp();
  const Example = () => {
    return <Lottie animationData={touchy} />;
  };
  const openURL = (url) => {
    window.open(url, "_blank");
  };
  const handleVideo = (url) => {
    setActiveVideo(url);
  };

  const handleIpad = (url) => {
    if(url.includes("tulip-player")){
      window.open(url, "_blank");
    }
    else{
      setActiveIpad(selectedAsset);
    }
    
  };
  return (
    <div
      className="assetContainer"
      style={{
        width: discover ? "30vw" : selectedAsset ? "30vw" : "0vw",
      }}
    >
      <div
        className="paneldetail"
        style={{
          backgroundColor: "#FFF",
          padding: "15px",
          border: "3px solid #939EAB",
          opacity: selectedAsset ? "1" : "0",
          borderRadius: "20px",
          fontSize: "20px",
          display: "flex",
          zIndex: 2,
          flexDirection: "column",
          "--delay": selectedAsset ? "0s" : "0s",
          "--duration": selectedAsset ? ".5s" : "0s",
          gap: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            zIndex: 1,
          }}
        >
          <div style={{ fontFamily: "Messina Sans" }}>
            {" "}
            {selectedAsset && selectedAsset.Title}{" "}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedAsset(null);
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div style={{ position: "relative" }}>
          {selectedAsset && !selectedAsset.video && (
            <IconButton
              onClick={() => handleVideo(selectedAsset.img)}
              style={{
                opacity: 1,
                width: 100,
                height: 100,
                zIndex: 5,
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            ></IconButton>
          )}
          {selectedAsset && selectedAsset.video && (
            <IconButton
              onClick={() => handleVideo(selectedAsset.video)}
              style={{
                opacity: 1,
                width: 100,
                height: 100,
                zIndex: 5,
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <PlayCircleIcon style={{ opacity: 1, width: 80, height: 80 }} />
            </IconButton>
          )}
          <img
            style={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
            src={selectedAsset && selectedAsset.img}
          />
        </div>
        <div style={{ fontFamily: "Messina Sans Light", fontSize: 16, lineHeight:"19px" }}>
          {selectedAsset && selectedAsset.desc}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          {selectedAsset && selectedAsset.library ? (
            <div
              className="chip"
              onClick={() => openURL(selectedAsset.library)}
            >
              Library
            </div>
          ) : null}
          {selectedAsset && selectedAsset.browserUrl && (
            <div className="chip" onClick={() => handleIpad(selectedAsset.browserUrl)}>
              Run App
              <LaunchIcon fontSize="small" sx={{ paddingLeft: 1 }} />
            </div>
          )}
          {selectedAsset &&
          !selectedAsset.browserUrl &&
          selectedAsset.appUrl ? (
            <div className="chip" onClick={() => openURL(selectedAsset.appUrl)}>
              Open Player{" "}
              <LaunchIcon fontSize="small" sx={{ paddingLeft: 1 }} />
            </div>
          ) : null}
          {selectedAsset && selectedAsset.editorUrl ? (
            <div
              className="chip"
              onClick={() => openURL(selectedAsset.editorUrl)}
            >
              Open Editor
              <LaunchIcon fontSize="small" sx={{ paddingLeft: 1 }} />
            </div>
          ) : null}
          {selectedAsset && selectedAsset.contentUrl ? (
            <div
              className="chip"
              onClick={() => openURL(selectedAsset.contentUrl)}
            >
              More info
              <LaunchIcon fontSize="small" sx={{ paddingLeft: 1 }} />
            </div>
          ) : null}
        </div>
      </div>

      {discover && !youarehere && (
        <div
          className="discover"
          style={{
            position: "absolute",
            top: "30%",
            maxWidth: "30vw",
            padding: "50px",
            fontFamily: "Messina Sans",
            color: theme.palette.customColors.gray,
            zIndex: 0,
            opacity: selectedAsset ? "0" : "1",
            "--delay": selectedAsset ? "0s" : "0s",
            "--duration": selectedAsset ? "0s" : ".5s",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="h4" paddingBottom={3}>{discoverHeader ?? "Discover Tulip Apps"}</Typography>
          <p style={{ fontFamily: "Messina Sans Light" }}>{discover}</p>
          {false && (
            <div style={{ maxWidth: "300px", alignSelf: "center", zIndex: 0 }}>
              <Example />
            </div>
          )}
        </div>
      )}

      {youarehere && (
        <div
          className="discover"
          style={{
            position: "absolute",
            top: "50%",
            maxWidth: "30vw",
            padding: "50px 50px 50px 0px",
            transform: "translateY(-50%)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontFamily: "Messina Sans",
            color: "#becdd5",
            zIndex: 0,
            fontSize: "large",
            opacity: selectedAsset ? "0" : "1",
            "--delay": selectedAsset ? "0s" : "0s",
            "--duration": selectedAsset ? "0s" : ".5s",
          }}
        >
          <LocationOnIcon style={{ fontSize: "50px", color: theme.palette.customColors.highlight }} />
          <h1 style={{ paddingLeft: 10 }}>You are here</h1>
        </div>
      )}
    </div>
  );
}
