import { useState } from "react";
import { useAuth } from "./useAuth";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../firebase/config";
import { useLogout } from "./useLogout";
import { useDocs } from "./useDocs";

export const useGoogle = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatchUser } = useAuth();
  const { getPrivateAccount, getAdminRecord } = useDocs("partnerDetails");
  const { logout } = useLogout();
  const extractDomain = (email) => {
    const domain = email.split("@")[1];
    return domain || null;
  };
  const loginGoogle = async () => {
    setError(null);
    setIsPending(true);
    //sign the user out
    try {
      //sign in user
      //const pers = await setPersistence(auth, browserSessionPersistence)
      const provider = new GoogleAuthProvider();
      const res = await signInWithPopup(auth, provider);
      console.log(res);

      if (!res) {
        throw new Error("could not complete signup");
      }
      const credential = GoogleAuthProvider.credentialFromResult(res);
      const token = credential.accessToken;
      setIsPending(false);
      setError(null);
      //dispatch login action
      if (!res.user.email.includes("@tulip.co")) {
        logout();
        throw new Error("This is not a valid tulip.co email");
      } else {
        let privateAccount = null;
        let email = res.user.email;
        let emailSuffix = extractDomain(email);
        let account = await getPrivateAccount(emailSuffix);
        if(account && account.hasOwnProperty("isApproved") && account.isApproved === true){
          privateAccount = account;

        }
        let admin = await getAdminRecord(res.user);
        dispatchUser({
          type: "LOGIN",
          payload: { ...res.user, approved: true, partnerAccount: null, privateAccount: privateAccount, admin: admin ?? false },
        });
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };
  return { loginGoogle, errorGoogle: error, isPendingGoogle: isPending };
};
