import React from 'react';

// Component to create a line of spotlights with configurable properties
const SpotlightArray = ({
  startPosition = [0, 10, -50],  // Starting point [x, y, z]
  endPosition = [0, 10, 50],     // Ending point [x, y, z]
  count = 5,                    // Number of lights to place
  spacing = null,               // Optional fixed spacing (overrides count if provided)
  height = null,                // Optional override for y position
  intensity = 1,                // Light intensity
  color = '#ffffff',            // Light color
  castShadow = true,            // Whether lights cast shadows
  angle = 0.5,                  // Spotlight angle in radians
  penumbra = 0.5,               // Spotlight penumbra (edge softness)
  decay = 2,                    // Light decay
  distance = 50,                // Maximum distance the light reaches
  power = 30                    // Light power
}) => {
  // Calculate positions for the lights
  const lightPositions = React.useMemo(() => {
    const positions = [];
    const [x1, y1, z1] = startPosition;
    const [x2, y2, z2] = endPosition;
    const yPos = height !== null ? height : y1; // Use override height if provided
    
    // Direction vector
    const dx = x2 - x1;
    const dz = z2 - z1;
    
    // Total distance
    const totalDistance = Math.sqrt(dx * dx + dz * dz);
    
    // Determine spacing between lights
    let actualSpacing;
    let actualCount;
    
    if (spacing !== null) {
      // If fixed spacing is provided, calculate how many lights we need
      actualSpacing = spacing;
      actualCount = Math.floor(totalDistance / spacing) + 1;
    } else {
      // Otherwise use the count to determine spacing
      actualCount = Math.max(2, count); // Minimum 2 lights
      actualSpacing = totalDistance / (actualCount - 1);
    }
    
    // Direction unit vector
    const dirX = dx / totalDistance;
    const dirZ = dz / totalDistance;
    
    // Create light positions
    for (let i = 0; i < actualCount; i++) {
      const t = i * actualSpacing;
      const x = x1 + dirX * t;
      const z = z1 + dirZ * t;
      positions.push([x, yPos, z]);
    }
    
    return positions;
  }, [startPosition, endPosition, count, spacing, height]);

  return (
    <group>
      {lightPositions.map((position, index) => (
        <spotLight
          key={`spotlight-${index}`}
          position={position}
          intensity={intensity}
          color={color}
          castShadow={castShadow}
          angle={angle}
          penumbra={penumbra}
          decay={decay}
          distance={distance}
          power={power}
        />
      ))}
    </group>
  );
};

// Component to create a line of point lights with configurable properties
const PointLightArray = ({
  startPosition = [0, 10, -50],  // Starting point [x, y, z]
  endPosition = [0, 10, 50],     // Ending point [x, y, z]
  count = 5,                     // Number of lights to place
  spacing = null,                // Optional fixed spacing (overrides count if provided)
  height = null,                 // Optional override for y position
  intensity = 1,                 // Light intensity
  color = '#ffffff',             // Light color
  castShadow = true,             // Whether lights cast shadows
  decay = 2,                     // Light decay
  distance = 50                  // Maximum distance the light reaches
}) => {
  // Calculate positions for the lights
  const lightPositions = React.useMemo(() => {
    const positions = [];
    const [x1, y1, z1] = startPosition;
    const [x2, y2, z2] = endPosition;
    const yPos = height !== null ? height : y1; // Use override height if provided
    
    // Direction vector
    const dx = x2 - x1;
    const dz = z2 - z1;
    
    // Total distance
    const totalDistance = Math.sqrt(dx * dx + dz * dz);
    
    // Determine spacing between lights
    let actualSpacing;
    let actualCount;
    
    if (spacing !== null) {
      // If fixed spacing is provided, calculate how many lights we need
      actualSpacing = spacing;
      actualCount = Math.floor(totalDistance / spacing) + 1;
    } else {
      // Otherwise use the count to determine spacing
      actualCount = Math.max(2, count); // Minimum 2 lights
      actualSpacing = totalDistance / (actualCount - 1);
    }
    
    // Direction unit vector
    const dirX = dx / totalDistance;
    const dirZ = dz / totalDistance;
    
    // Create light positions
    for (let i = 0; i < actualCount; i++) {
      const t = i * actualSpacing;
      const x = x1 + dirX * t;
      const z = z1 + dirZ * t;
      positions.push([x, yPos, z]);
    }
    
    return positions;
  }, [startPosition, endPosition, count, spacing, height]);

  return (
    <group>
      {lightPositions.map((position, index) => (
        <pointLight
          key={`pointlight-${index}`}
          position={position}
          intensity={intensity}
          color={color}
          castShadow={castShadow}
          decay={decay}
          distance={distance}
        />
      ))}
    </group>
  );
};

// Export both components
export { SpotlightArray, PointLightArray };

// Default export with both components
const LightArrays = {
  SpotlightArray,
  PointLightArray
};

export default LightArrays;