import { useState } from "react";
import TextField from "@mui/material/TextField";
import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import { useLogin } from "../../hooks/useLogin";
import { Link } from "react-router-dom";

//pages and components
export default function PartnerLogin() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { loginEmail, loginError } = useLogin();

  const handleSubmit = () => {
    if (email && password) {
      loginEmail(email, password);
    }
  };

  const handleInput = (event) => {
    const { value, id } = event.target;
    switch (id) {
      case "email":
        setEmail(value);
        return;
      case "password":
        setPassword(value);
        return;
    }
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      height={"100vh"}
    >
      <Grid
        item
        backgroundColor={"#FFF"}
        xs={6}
        md={4}
        padding={4}
        borderRadius={2}
      >
        <Stack gap={2}>
          <Typography variant="h6">Partner Login</Typography>
          <TextField
            id="email"
            label="Email"
            value={email}
            onChange={handleInput}
            autoComplete="current-email"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="password"
            type="password"
            label="Password"
            value={password}
            onChange={handleInput}
            autoComplete="current-password"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Stack direction={"row"} justifyContent={"end"} flexItem>
            <Button component={Link} to={"/signup"} xs>
              Signup
            </Button>
            <Button xs onClick={handleSubmit} variant="contained">
              Login
            </Button>
          </Stack>
          {loginError && (
            <Typography>
              There was an issue with your login information, you may need to
              sign up
            </Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
