import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useApp } from "../hooks/useApp";

const Filter = () => {
  const { user } = useAuth();
  const { filters, setSelectedFilter, selectedFilter } = useApp();

  return (
    Array.isArray(filters) &&
    filters.length > 0 && (
      <Autocomplete
        className="w-100"
        options={filters.filter(
          (item) =>
            item.isHidden !== true &&
            (item.createdByAdmin === true || item.userId === user.uid)
        )}
        getOptionLabel={(option) => option.name}
        value={selectedFilter || null}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        sx={{ backgroundColor: "#FFF" }}
        id="filter"
        disableClearable
        onChange={(event, newValue) => {
          setSelectedFilter(newValue);
        }}
        renderInput={(params) => <TextField {...params} label="Filter" />}
      />
    )
  );
};

export default Filter;
