import React, { useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import AutoComplete from "../../components/Common/AutoComplete";
import Navbar from "../../components/Navbar";
import { useApp } from "../../hooks/useApp";
import { useDocs } from "../../hooks/useDocs";
import { useAuth } from "../../hooks/useAuth";
import { emitToast } from "../../common/toastEmitter";
import { useCollection } from "../../hooks/useCollection";
import { DataGrid } from "@mui/x-data-grid";
import DialogBox from "../../components/Common/DialogBox";

const defaultFilter = {
  id: "",
  name: "",
  filter: {
    factories: [],
    megafactories: [],
    apps: [],
    beacons: [],
    videos: [],
  },
  isAdminDefault: false,
  isHidden: false,
  createdByAdmin: false,
};
const columns = [
  { field: "id", headerName: "Id", width: 250 },
  { field: "name", headerName: "Name", width: 250 },
  { field: "isAdminDefault", headerName: "Is Admin Default", width: 200 },
  { field: "isHidden", headerName: "Hidden", width: 200 },
];
const ManageFilters = () => {
  const { documents: views } = useCollection("views");
  const { combinedAssets } = useApp();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const { addViewDocument, deleteDocument, addViewsToPartner } = useDocs();
  const { user } = useAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [filterList, setFilterList] = useState([]);

  useEffect(() =>{
    if(Array.isArray(views) && views.length > 0){
      if(user?.admin){
        setFilterList(views)
      }
      else{
        const filteredViews = views.filter((item) => item.userId === user?.uid).map((item) => ({
          ...item,
          isAdminDefault: user?.defaultView && user?.defaultView === item.id
        }));
        setFilterList(filteredViews);
      }
    }
  },[views, user])

  const assetTypes = useMemo(() => {
    if (!combinedAssets) return {};

    const types = ["megafactory", "factory", "app", "beacon", "video"];
    const defaultItem = { id: "all", title: "All" };

    // Apply privacy filtering first
    const filteredAssets = user?.admin 
      ? combinedAssets 
      : user?.domain 
        ? combinedAssets.filter(asset => !asset.private || user.domain === asset.private)
        : combinedAssets.filter(asset => !asset.private);

    return types.reduce((acc, type) => {
      acc[type] = [
        defaultItem, // Add the default object at the top
        ...filteredAssets
          .filter((asset) => asset.Type === type)
          .map(({ id, Title }) => ({ id, title: Title })),
      ];
      return acc;
    }, {});
  }, [combinedAssets, user]);
  const assetTypesArray = [
    {
      name: "Mega Factory",
      key: "megafactories",
      assets: assetTypes.megafactory,
    },
    { name: "Factory", key: "factories", assets: assetTypes.factory },
    { name: "App", key: "apps", assets: assetTypes.app },
    { name: "Video", key: "videos", assets: assetTypes.video },
    { name: "Beacons", key: "beacons", assets: assetTypes.beacon },
  ];
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedFilter((prev) => ({ ...prev, [name]: value }));
  };

  const handleFilterChange = (filterType, selectedValues) => {
    const allSelected = selectedValues.some(item => item.id === "all");
    const ids = allSelected ? ["all"] : selectedValues.map(item => item.id);

    setSelectedFilter((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        [filterType]: ids,
      },
    }));
  };
  const handleSave = async () => {
    var objectToSave = {
      ...selectedFilter,
      createdByAdmin: user?.admin ?? false,
      isAdminDefault: !user?.admin ? false : selectedFilter.isAdminDefault,
      userId: user.uid,
    };
    try {
      var response = await addViewDocument(objectToSave);

      if(!user?.admin){

        const viewId = selectedFilter.id ?? response.id;
        let userViews = [];
        if(user.hasOwnProperty("views")){
          userViews = user.views;
          if(!userViews.includes(selectedFilter.id)){
            userViews.push(viewId);
          }
        }
        else{
          userViews.push(viewId)
        }
        const userViewsData = {
          views: userViews,
          ...(selectedFilter.isAdminDefault && { defaultView: viewId })
        };
        await addViewsToPartner(userViewsData, user.uid);
      }
      if (selectedFilter.id) {
        emitToast("View Updated Successfully", "success");
      } else {
        emitToast("View Saved Successfully", "success");
      }
      setSelectedFilter(defaultFilter);
    } catch (error) {
      emitToast("Error occurred while saving the view", "error");
    }
  };
  const handleCellClick = (params) => {
    setSelectedFilter(params.row);
  };

  const handleDelete = async () => {
    try {
      await deleteDocument(selectedFilter.id, "views");
      if(!selectedFilter.createdByAdmin && selectedFilter.userId === user.uid){
        if(user?.views && Array.isArray(user.views) && user.views.length > 0){
          const newViews = user.views.filter(x => x !== selectedFilter.id);
          const defaultView = user?.defaultView === selectedFilter.id ? "" : user?.defaultView;
          const userViewData = {
            views: newViews,
            defaultView: defaultView
          }
          await addViewsToPartner(userViewData, user.uid);
        }
      }
      setOpenDialog(false);
      emitToast("View Deleted Successfully", "error");
    } catch (error) {
      emitToast("Error occurred while saving the view", "error");
    }
  };
  return (
    <div>
      <Navbar sx={{ backgroundColor: "grey" }} />

      <Grid
        container
        gap={2}
        height="100%"
        padding={2}
        className="accounts_grid lg:!flex-nowrap"
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{ height: "calc(100% - 65px)", backgroundColor: "white" }}
          padding={1}
        >
          <Typography marginTop={1} fontWeight={600}>
            Manage Views
          </Typography>
          <DataGrid
            rows={filterList || []}
            columns={columns}
            onCellClick={handleCellClick}
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 20 } },
            }}
            pageSizeOptions={[20, 40]}
            checkboxSelection={false}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ height: "calc(100% - 65px)", backgroundColor: "white" }}
          padding={2}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography marginTop={1} fontWeight={600}>
              View
            </Typography>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setSelectedFilter(defaultFilter)}
            >
              Create New
            </Button>
          </Stack>
          {selectedFilter && (
            <>
              <Stack direction="column" spacing={2} marginTop={3}>
                <TextField
                  label="Id"
                  name="id"
                  value={selectedFilter.id}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  disabled
                />
                <TextField
                  label="Name"
                  name="name"
                  value={selectedFilter.name}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  onChange={handleInputChange}
                />
                {assetTypesArray.map(({ name, key, assets }) => (
                  <AutoComplete
                    key={key}
                    name={name}
                    assets={assets}
                    onChange={(e, val) => handleFilterChange(key, val)}
                    defaultValue={selectedFilter.filter[key]}
                    isOptionDisabled={(option) =>
                      selectedFilter.filter[key].length > 0 &&
                      option.id === "all"
                    }
                  />
                ))}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFilter.isAdminDefault}
                      onChange={(e) =>
                        setSelectedFilter((prev) => ({
                          ...prev,
                          isAdminDefault: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Is Default"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFilter.isHidden}
                      onChange={(e) =>
                        setSelectedFilter((prev) => ({
                          ...prev,
                          isHidden: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Is Hidden"
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                padding={1}
                justifyContent={"space-between"}
              >
                {selectedFilter.id ? (
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => setOpenDialog(true)}
                  >
                    Delete
                  </Button>
                ) : (
                  <div></div>
                )}

                <Button
                  color="success"
                  variant="contained"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Stack>
            </>
          )}
        </Grid>
      </Grid>
      <DialogBox
        open={openDialog}
        setOpen={setOpenDialog}
        handleDelete={handleDelete}
      />
      
    </div>
  );
};

export default ManageFilters;
