import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";

export const useSlack = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  // const { selectedAsset, selectedOpp } = useApp();

  // const sendSlack = async (notes) => {
  //   setError(null);
  //   setIsPending(true);

  //   try {
  //     //signup user
  //     const res = await httpsCallable(
  //       functions,
  //       "send_message"
  //     )({
  //       opportunityId: selectedOpp.id,
  //       opportunityName: selectedOpp.name,
  //       notes: notes,
  //     });
  //     if (!res) {
  //       throw new Error("could not complete signup");
  //     }

  //     console.log(res);
  //     setIsPending(false);
  //     setError(null);
  //   } catch (err) {
  //     console.log(err.message);
  //     setError(err.message);
  //     setIsPending(false);
  //   }
  // };

  const genericSlack = async (message) => {
    setError(null);
    setIsPending(true);

    try {
      //signup user
      const res = await httpsCallable(
        functions,
        "generic_slack"
      )({ message: message });
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };
  return { error, isPending, genericSlack };
};
