import { useState } from "react";
import { storage } from "../firebase/config";
import {
    deleteObject,
    getDownloadURL,
    ref as storageRef,
    uploadBytes,
    listAll
  } from "firebase/storage";

export const useStorage = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const uploadFile = async (file, folder) => {
    try {
      setError(null);
      setIsPending(true);

      const imageRef = storageRef(storage, `${folder}/${file.name}`);
      const listRef = storageRef(storage, `${folder}/`);
      const { items } = await listAll(listRef);

      const existingFile = items.find((item) => item.name === file.name);
      const imageUrl = existingFile
        ? await getDownloadURL(existingFile)
        : await getDownloadURL(
            await uploadBytes(imageRef, file).then((snapshot) => snapshot.ref)
          );

      setIsPending(false);
      return imageUrl;
    } catch (error) {
      console.log(error.message);
      setError(error.message);
      setIsPending(false);
    }
  };

  const deleteFile = async (filePath) => {
    try {
      setError(null);
      setIsPending(true);

      const fileRef = storageRef(storage, filePath);
      await deleteObject(fileRef);
      setIsPending(false);
    } catch (error) {
      console.log(error.message);
      setError(error.message);
      setIsPending(false);
    }
  };
  return { error, isPending, uploadFile , deleteFile};
};
