import { useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import ReactFlow from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "./CustomNode";
import { useParams } from "react-router-dom";
import { useCollection } from "../../hooks/useCollection";

const nodeTypes = { customNode: CustomNode };

const flowDataArray = [
  {
    id: "flow1",
    title: "Flow 1",
    description: "jfsdghgjkdshfbv",
    flow: [
      {
        id: "1",
        title: "ONE",
        description: "sdg sjkgdfhjk sdgj ksjhdcjk syfuw sdkj ",
        source: "1",
        target: "2",
        sourcePosition: "bottom",
        targetPosition: "top",
        nodeType: "customNode",
        active: false,
        position: { x: 0, y: 0 },
      },
      {
        id: "2",
        title: "TWO",
        description: "",
        source: "2",
        target: "3",
        sourcePosition: "right",
        targetPosition: "top",
        nodeType: "customNode",
        active: false,
        position: { x: 0, y: 200 },
      },
      {
        id: "3",
        title: "THREE",
        description: "",
        source: "3",
        target: "4",
        sourcePosition: "top",
        targetPosition: "left",
        nodeType: "customNode",
        active: false,
        position: { x: 400, y: 200 },
      },
      {
        id: "4",
        title: "FOUR",
        description: "",
        source: "4",
        target: "",
        sourcePosition: "left",
        targetPosition: "bottom",
        nodeType: "customNode",
        active: false,
        position: { x: 400, y: 0 },
      },
    ],
  },
  {
    id: "flow2",
    title: "Flow 2",
    description: "jfsdghgjkdshfbv",
    flow: [
      {
        id: "1",
        title: "ONE",
        description: "sdg sjkgdfhjk sdgj ksjhdcjk syfuw sdkj ",
        source: "1",
        target: "2",
        sourcePosition: "bottom",
        targetPosition: "top",
        nodeType: "customNode",
        active: false,
        position: { x: 0, y: 0 },
      },
      {
        id: "2",
        title: "TWO",
        description: "",
        source: "2",
        target: "3",
        sourcePosition: "right",
        targetPosition: "top",
        nodeType: "customNode",
        active: false,
        position: { x: 0, y: 200 },
      },
      {
        id: "3",
        title: "THREE",
        description: "",
        source: "3",
        target: "4",
        sourcePosition: "top",
        targetPosition: "left",
        nodeType: "customNode",
        active: false,
        position: { x: 400, y: 200 },
      },
      {
        id: "4",
        title: "FOUR",
        description: "",
        source: "4",
        target: "",
        sourcePosition: "left",
        targetPosition: "bottom",
        nodeType: "customNode",
        active: false,
        position: { x: 400, y: 0 },
      },
    ],
  },
];

const Flow = () => {
  
  const { flowId } = useParams();
  const theme = useTheme();
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [flowData, setFlowData] = useState(null);
  const flowpages = useCollection("flowpage").documents;
  const flownodes = useCollection("flows").documents;


  useEffect(() => {
    if (flowId && flowpages && flownodes) {
      const flow = flowpages.find((item) => item.id === flowId);
      const flowNodes = flownodes.filter((item) => flow.nodes.includes(item.id))
      if (flowNodes) {

        const initialNodes = flowNodes.map((item) => ({
          id: item.id,
          position: item.position,
          data: {
            ...item,
            onClick: handleNodeClick,
          },
          type: item.nodeType || "default",
        }));

        const initialEdges = flowNodes
          .filter((item) => item.source && item.target)
          .map((item) => ({
            id: `${item.source}-${item.target}`,
            source: item.source,
            target: item.target,
            animated: true,
            style: { stroke: "#fff" },
          }));
        setFlowData(flow);
        setNodes(initialNodes);
        setEdges(initialEdges);
      }
    }
  }, [flowId, flowpages, flownodes]);

  const handleNodeClick = (url) => {
    window.open(url, "_blank");
  };

  const bgColor = `linear-gradient(to left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`;
  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: 10,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 10,
          color: "white",
          fontSize: "26px",
          fontWeight: "bold",
        }}
      >
        {flowData && <Typography fontSize={'60px'}>{flowData.title}</Typography>}
      </div>
      <ReactFlow
        nodes={nodes}
        edges={[{id:'1', source:'assyexecution', target:'edhrtemplate', style:{stroke:'#fff'}}]}
        nodeTypes={nodeTypes}
        fitView
        style={{ background: bgColor }}
      />
    </div>
  );
};

export default Flow;
