import { useApp } from "../../hooks/useApp";
import { Modal } from "@mui/material";
import MegaFactoryContent from "../../components/MegaFactoryContent";

export default function Megafactory() {
  const {
    activeMegaFactory,
    setActiveMegaFactory,
  } = useApp();
  const handleClose = () => {
    setActiveMegaFactory(null);
  };

  return (
    <Modal
      open={activeMegaFactory ? true : false}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <MegaFactoryContent activeMegaFactory={activeMegaFactory} isModal={true}/>
    </Modal>
  );
}
