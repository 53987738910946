import { useState } from "react";
import { useSignup } from "../../hooks/useSignup";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Button } from "@mui/material";
import { useLogin } from "../../hooks/useLogin";
import BackdropLoader from "../../components/Common/BackdropLoader";

//pages and components
export default function SignupComponent({ setSignupFlag }) {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const { signup, error } = useSignup();
  const { loginError } = useLogin();
  const [ loading, setLoading ] = useState(false);

  const handleSubmit = async () => {
    try {
      if (password === password2) {
        setPasswordError(false);
      } else {
        setPasswordError(true);
        return;
      }
      if (name && email && password && password2 && password === password2) {
        setLoading(true);
        await signup(email, password, name);
        setLoading(false);
      }
    } catch (error) {
      console.error("Sign up method error : ", error);
      setLoading(false);
    }
  };

  const handleInput = (event) => {
    const { value, id } = event.target;
    switch (id) {
      case "email-input":
        setEmail(value);
        return;
      case "name-input":
        setName(value);
        return;
      case "password":
        setPassword(value);
        return;
      case "password2":
        setPassword2(value);
        return;
    }
  };

  return (
    <>
      <BackdropLoader open={loading} />
      <Stack gap={2}>
        <Typography variant="h6">Partner Signup</Typography>
        <TextField
          id="name-input"
          label="Name"
          onChange={handleInput}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="email-input"
          label="Email"
          value={email}
          onChange={handleInput}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="password"
          type="password"
          label="Password"
          value={password}
          onChange={handleInput}
          autoComplete="current-password"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="password2"
          type="password"
          label="Confirm Password"
          error={passwordError}
          value={password2}
          onChange={handleInput}
          autoComplete="current-password"
          helperText={passwordError ? "Passwords don't match" : null}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Stack direction={"row"} justifyContent={"end"} flexItem>
          <Button
            xs
            onClick={() => setSignupFlag(false)}
            sx={{ color: "primary.main" }}
          >
            Login
          </Button>
          <Button
            xs
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "secondary .main",
              },
            }}
          >
            Signup
          </Button>
        </Stack>
        {loginError && (
          <Typography>
            There was an issue with your login information, you may need to sign
            up
          </Typography>
        )}
        {error && <Typography>{error}</Typography>}
      </Stack>
    </>
  );
}
