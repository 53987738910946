import React, { useRef, useEffect, useCallback } from 'react';
import Character from './Character';
import VehicleCharacter from './VehicleCharacter';

// CharacterManager manages multiple characters and vehicles with their states and actions
const CharacterManager = ({ 
  characters = [], // Array of character configurations
  vehicles = [], // Array of vehicle configurations
  onActionComplete = () => {} // Global callback when any character/vehicle completes an action
}, ref) => {
  // Object to store refs to all characters and vehicles
  const characterRefs = useRef({});
  const vehicleRefs = useRef({});

  // Initialize character and vehicle refs
  useEffect(() => {
    // Reset refs if arrays change
    characterRefs.current = {};
    vehicleRefs.current = {};
  }, [characters, vehicles]);

  // Handle action completion and forward to parent
  const handleActionComplete = useCallback((entityId, actionName) => {
    console.log(`Entity ${entityId} completed action: ${actionName}`);
    onActionComplete(entityId, actionName);
  }, [onActionComplete]);

  // Get any entity (character or vehicle) by ID
  const getEntity = (entityId) => {
    return characterRefs.current[entityId] || vehicleRefs.current[entityId] || null;
  };

  // Method to execute an action on a specific entity
  const executeEntityAction = (entityId, actionName) => {
    const entity = getEntity(entityId);
    if (entity) {
      entity.executeAction(actionName);
      return true;
    }
    console.error(`Entity with ID ${entityId} not found`);
    return false;
  };

  // Method to execute an action on all entities
  const executeAllEntitiesAction = (actionName) => {
    // Execute on all characters
    Object.values(characterRefs.current).forEach(character => {
      if (character) {
        character.executeAction(actionName);
      }
    });
    
    // Execute on all vehicles
    Object.values(vehicleRefs.current).forEach(vehicle => {
      if (vehicle) {
        vehicle.executeAction(actionName);
      }
    });
  };

  // Character-specific methods
  const getCharacter = (characterId) => {
    return characterRefs.current[characterId] || null;
  };

  const executeCharacterAction = (characterId, actionName) => {
    const character = getCharacter(characterId);
    if (character) {
      character.executeAction(actionName);
      return true;
    }
    console.error(`Character with ID ${characterId} not found`);
    return false;
  };

  const setCharacterAnimationState = (characterId, state) => {
    const character = getCharacter(characterId);
    if (character) {
      character.setAnimationState(state);
      return true;
    }
    console.error(`Character with ID ${characterId} not found`);
    return false;
  };

  const walkCharacterTo = (characterId, position) => {
    const character = getCharacter(characterId);
    if (character) {
      character.walkTo(position);
      return true;
    }
    console.error(`Character with ID ${characterId} not found`);
    return false;
  };

  // Vehicle-specific methods
  const getVehicle = (vehicleId) => {
    return vehicleRefs.current[vehicleId] || null;
  };

  const executeVehicleAction = (vehicleId, actionName) => {
    const vehicle = getVehicle(vehicleId);
    if (vehicle) {
      vehicle.executeAction(actionName);
      return true;
    }
    console.error(`Vehicle with ID ${vehicleId} not found`);
    return false;
  };

  const driveVehicleTo = (vehicleId, position) => {
    const vehicle = getVehicle(vehicleId);
    if (vehicle) {
      vehicle.driveTo(position);
      return true;
    }
    console.error(`Vehicle with ID ${vehicleId} not found`);
    return false;
  };

  // Utility methods
  const getAllEntityIds = () => {
    return [
      ...Object.keys(characterRefs.current),
      ...Object.keys(vehicleRefs.current)
    ];
  };

  const getCharacterIds = () => {
    return Object.keys(characterRefs.current);
  };

  const getVehicleIds = () => {
    return Object.keys(vehicleRefs.current);
  };

  const getIdleEntities = () => {
    // Get idle characters
    const idleCharacters = Object.entries(characterRefs.current)
      .filter(([_, character]) => !character.isExecutingAction())
      .map(([id]) => id);
    
    // Get idle vehicles
    const idleVehicles = Object.entries(vehicleRefs.current)
      .filter(([_, vehicle]) => !vehicle.isExecutingAction())
      .map(([id]) => id);
    
    return [...idleCharacters, ...idleVehicles];
  };

  // Expose methods to parent via ref
  React.useImperativeHandle(ref, () => ({
    // Generic entity methods
    getEntity,
    executeEntityAction,
    executeAllEntitiesAction,
    getAllEntityIds,
    getIdleEntities,
    
    // Character-specific methods
    getCharacter,
    executeCharacterAction,
    setCharacterAnimationState,
    walkCharacterTo,
    getCharacterIds,
    
    // Vehicle-specific methods
    getVehicle,
    executeVehicleAction,
    driveVehicleTo,
    getVehicleIds
  }));

  return (
    <>
      {/* Render regular characters */}
      {characters.map((characterConfig) => (
        <Character
          key={characterConfig.id}
          ref={(el) => {
            if (el) characterRefs.current[characterConfig.id] = el;
          }}
          {...characterConfig}
          onActionComplete={handleActionComplete}
        />
      ))}
      
      {/* Render vehicle characters */}
      {vehicles.map((vehicleConfig) => (
        <VehicleCharacter
          key={vehicleConfig.id}
          ref={(el) => {
            if (el) vehicleRefs.current[vehicleConfig.id] = el;
          }}
          {...vehicleConfig}
          onActionComplete={handleActionComplete}
        />
      ))}
    </>
  );
};

export default React.forwardRef(CharacterManager);