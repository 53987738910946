import { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { useApp } from "../../hooks/useApp";
import {
  Box,
  Drawer,
  Button,
  InputAdornment,
  IconButton,
  Paper,
  Stack,
  TextField,
  Breadcrumbs,
  Typography,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import { useDocs } from "../../hooks/useDocs";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ToggleButton from "@mui/material/ToggleButton";
import { emitToast } from "../../common/toastEmitter";
import { useAuth } from "../../hooks/useAuth";

const columns = [
  { field: "id", headerName: "ID", width: 200 },
  { field: "Title", headerName: "Title", width: 260 },
  { field: "Heading", headerName: "Heading", flex: 1 },
  { field: "Type", headerName: "Type", width: 130 },
  { field: "status", headerName: "Status", width: 130 },
  { field: "email", headerName: "Email", width: 260 },
];

export default function Assets() {
  const { vtecAssetsUnsorted } = useApp();
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [freeSearch, setFreeSearch] = useState("");
  const { updateAsset, deleteAsset, getAllPrivateAssets } = useDocs("vtec_assets");
  const [crumbs, setCrumbs] = useState(null);
  const [reviewOnly, setReviewOnly] = useState(false);
  const [subObject, setSubObject] = useState(null);
  const { user } = useAuth();
  const [vtecAssets, setVtecAssets] = useState(vtecAssetsUnsorted);

  useEffect(() => {
    if (user) {
      const updatedAssets = user?.admin
        ? vtecAssetsUnsorted
        : vtecAssetsUnsorted.filter(
            (item) => item.userid && item.userid === user.uid
          );

      if (vtecAssets !== updatedAssets) {
        setVtecAssets(updatedAssets);
      }
    }
  }, [user, vtecAssetsUnsorted, vtecAssets]);

  const handleSave = () => {
    updateAsset(selectedAsset);
    emitToast("Asset Saved Successfully", "success");
  };

  const handleDelete = () => {
    deleteAsset(selectedAsset);
    setSelectedAsset(null);
    emitToast("Asset Deleted Successfully", "success");
  };
  const handleSaveSubObject = () => {
    let subObjectKey = [Object.keys(subObject)[0]];
    let newAsset = {
      ...selectedAsset,
      [subObjectKey]: subObject[subObjectKey],
    };
    setSelectedAsset(newAsset);
    updateAsset(newAsset);
    setSubObject(null);
    emitToast("Asset Saved Successfully", "success");
  };

  const toggleNewReq = (index) => {
    let newSubObjectValue = subObject[Object.keys(subObject)[0]].map(
      (item, i) => {
        if (i === index) {
          if (item.hasOwnProperty("newReq")) {
            const { newReq, ...rest } = item; // Remove the 'newReq' key
            return rest;
          } else {
            return { ...item, newReq: true }; // Add the 'newReq' key with value true
          }
        }
        return item;
      }
    );
    let newSubObject = { [Object.keys(subObject)[0]]: newSubObjectValue };
    setSubObject(newSubObject);
  };

  const handleChange = (key, value) => {
    setSelectedAsset((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubChange = (key, value, index) => {
    let subObjectKey = Object.keys(subObject)[0];
    let subObjectValue = subObject[subObjectKey];

    if (Array.isArray(subObjectValue)) {
      if (typeof subObjectValue[index] === "object") {
        setSubObject({
          [subObjectKey]: subObjectValue.map((item, i) =>
            i === index ? { ...item, [key]: value } : item
          ),
        });
      } else {
        setSubObject({
          [subObjectKey]: subObjectValue.map((item, i) =>
            i === index ? value : item
          ),
        });
      }
    }
  };

  const handleEnterItem = (key, value) => {
    setSubObject({ [key]: value });
    setCrumbs((prev) => [...prev, { [key]: value }]);
  };

  useEffect(() => {
    if (selectedAsset) {
      setCrumbs([selectedAsset]);
    } else {
      setCrumbs(null);
    }
  }, [selectedAsset]);

  const handleCellClick = (asset) => {
    setSelectedAsset({
      ...asset,
      isHidden: asset.hasOwnProperty('isHidden') ? asset.isHidden : false
    });
  }
  const handleSavePrivateAccounts = async () => {
    await getAllPrivateAssets();
  }

  return (
    <Stack
      container
      sx={{
        direction: "column",
        maxHeight: "100vh",
        maxWidth: "100%",
        display: "flex",
      }}
    >
      <Navbar />
      <Stack
        direction={"row"}
        sx={{ marginTop: 4, paddingY: 1 }}
        className="mx-3 md:mx-8"
      >
        <TextField
          size="small"
          label="Quick Search"
          sx={{ bgcolor: "background.paper" }}
          value={freeSearch}
          className="!mr-1 max-w-[60%] md:max-w-[30%]"
          onChange={(e) => setFreeSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <ToggleButton
          value="review"
          size="small"
          color="primary"
          selected={reviewOnly}
          onChange={() => {
            setReviewOnly(!reviewOnly);
          }}
        >
          Needs Review
        </ToggleButton>
        <Button onClick={handleSavePrivateAccounts}>Save Data</Button>
      </Stack>
      <Drawer
        anchor={"right"}
        open={selectedAsset ? true : false}
        onClose={() => {
          setSelectedAsset(null);
          setSubObject(null);
        }}
        sx={{
          padding: 2,
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box sx={{ padding: 2, bgcolor: "background.default" }}>
          <Breadcrumbs>
            {crumbs &&
              crumbs.map((crumb, index) => (
                <Typography key={index}>
                  {crumb.Title ?? Object.keys(crumb)[0]}
                </Typography>
              ))}
          </Breadcrumbs>
        </Box>
        <Paper
          sx={{
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            gap: 2,
            padding: 2,
            marginY: 2,
            height: "100%",
          }}
          elevation={0}
          className="w-[330px] md:w-[500px]"
        >
          {selectedAsset &&
            !subObject &&
            Object.entries(selectedAsset).map(([key, value]) =>
              Array.isArray(value) ? (
                value.length > 0 && (
                  <Button
                    key={key}
                    variant="outlined"
                    className="arrow-button"
                    endIcon={<ArrowForwardIosIcon />}
                    fullWidth={true}
                    onClick={() => {
                      handleEnterItem(key, value);
                    }}
                    sx={{ justifyContent: "space-between", color: "black" }}
                  >
                    {key}
                  </Button>
                )
              ) : key === "isHidden" ? (
                <FormControlLabel control={<Checkbox checked={value} onChange={(e) => handleChange(key, e.target.checked)} />} label="Is Hidden" />
              ) : (
                <TextField
                  key={key}
                  label={key}
                  value={value || ""}
                  className="edit-inputs"
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={key === "id" ? true : false}
                  onChange={(e) => handleChange(key, e.target.value)}
                />
              )
            )}
          {selectedAsset &&
            subObject &&
            subObject[Object.keys(subObject)[0]].map((value, index) => {
              if (typeof value == "object") {
                return (
                  <Box
                    key={index}
                    sx={{ gap: 1.5, display: "flex", flexDirection: "column" }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <IconButton size={"small"} disabled>
                        <DeleteIcon />
                      </IconButton>
                      <Typography>{index}</Typography>
                      <IconButton size={"small"} disabled>
                        <ArrowCircleDownIcon />
                      </IconButton>
                      <IconButton size={"small"} disabled>
                        <ArrowCircleUpIcon />
                      </IconButton>
                      {!value["newReq"] && (
                        <IconButton
                          disabled
                          size={"small"}
                          onClick={() => {
                            toggleNewReq(index);
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Stack>
                    {Object.entries(value).map(([key, value]) => {
                      if (key !== "newReq") {
                        return (
                          <TextField
                            key={key}
                            label={key}
                            value={value || ""}
                            fullWidth
                            sx={{ paddingLeft: 1 }}
                            size="small"
                            variant="outlined"
                            onChange={(e) =>
                              handleSubChange(key, e.target.value, index)
                            }
                          />
                        );
                      } else {
                        return (
                          <Stack
                            key={key}
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <TextField
                              label={"Enter Key"}
                              sx={{ paddingLeft: 1 }}
                              size="small"
                              variant="outlined"
                            />
                            <TextField
                              label={"value"}
                              sx={{ paddingLeft: 1 }}
                              size="small"
                              variant="outlined"
                            />
                            <IconButton
                              onClick={() => {
                                toggleNewReq(index);
                              }}
                            >
                              <DoNotDisturbAltIcon color="error" />
                            </IconButton>
                            <IconButton>
                              <CheckCircleOutlineIcon color="success" />
                            </IconButton>
                          </Stack>
                        );
                      }
                    })}
                  </Box>
                );
              } else {
                return (
                  <Stack direction={"row"}>
                    <TextField
                      key={index}
                      label={index}
                      value={value}
                      fullWidth
                      size="small"
                      variant="outlined"
                      onChange={(e) =>
                        handleSubChange("none", e.target.value, index)
                      }
                    />
                    <IconButton size={"small"} disabled>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                );
              }
            })}
        </Paper>
        <Box sx={{ padding: 2, bgcolor: "background.default" }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Button
              color="success"
              variant="contained"
              onClick={subObject ? handleSaveSubObject : handleSave}
            >
              Save
            </Button>
            <Button color="error" variant="contained" onClick={handleDelete}>
              Delete
            </Button>
          </Stack>
        </Box>
      </Drawer>
      <DataGrid
        rows={vtecAssets
          .filter((asset) => {
            if (!asset || !asset.Title) {
              return false;
            }
            return asset.Title.toLowerCase().includes(freeSearch.toLowerCase());
          })
          .filter((asset) => (reviewOnly ? asset.status === "REVIEW" : true))}
        columns={columns}
        autoPageSize={false}
        disableAutosize={false}
        value={selectedAsset}
        onCellClick={(params) => handleCellClick(params.row)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 40 },
          },
        }}
        pageSizeOptions={[20, 40]}
        checkboxSelection={false}
        className="mx-3 md:mx-8 mb-3"
        sx={{
          bgcolor: "background.paper",
        }}
      />
    </Stack>
  );
}
