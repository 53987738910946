import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { useCollection } from "../hooks/useCollection";
import { useApp } from "../hooks/useApp";
import { useDocs } from "../hooks/useDocs";

export default function Tagger() {
  const { documents: useCases } = useCollection("use_cases");
  const { beginUseCase, selectedAsset } = useApp();
  const { setDocument } = useDocs("vtec_assets");

  // Define the custom order for categories

  const customOrder = [
    "Digital Guidance",
    "Production Management",
    "Quality",
    "Compliance & Traceability",
    "Maintenance",
    "Inventory",
  ];

  // Sort the array based on the custom order
  const sortedDocuments = useCases
    ? useCases.sort(
        (a, b) =>
          customOrder.indexOf(a.solution) - customOrder.indexOf(b.solution)
      )
    : [{ usecase: "loading" }];
  const currentTags = selectedAsset.tags ? selectedAsset.tags : [];
  const selectedValues = sortedDocuments.filter((useCase) =>
    currentTags.includes(useCase.id)
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          size="small"
          multiple
          id="tags-outlined"
          limitTags={3}
          options={
            sortedDocuments.length > 0
              ? sortedDocuments
              : [{ usecase: "loading" }]
          }
          sx={{ backgroundColor: "#FFF" }}
          getOptionLabel={(option) => option.usecase}
          groupBy={(option) => option.solution}
          value={selectedValues}
          filterSelectedOptions
          onChange={(event, newValue) => {
            const tagIds = newValue.map((tag) => tag.id);
            setDocument(selectedAsset, { tags: tagIds });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Tagged Use Cases" />
          )}
        />
      </Grid>
    </Grid>
  );
}
