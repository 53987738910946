import "./CommonDataModel.css";
import React from "react";
import { useCdm } from "../../hooks/useCdm";
import { useEffect } from "react";
import ReactFlow, {
  Background,
  Controls,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
  useNodesInitialized,
  useUpdateNodeInternals,
} from "reactflow";
import "reactflow/dist/style.css";
import TableNode from "../../components/TableNode";
import { makeLayout } from "./Utils.js";

const nodeTypes = { tableNode: TableNode };

// const hexToRgb = (hex, alpha) => {
//   // Remove the leading # if present
//   hex = hex.replace(/^#/, "");

//   // Parse the hex value into its components
//   let r = parseInt(hex.substring(0, 2), 16);
//   let g = parseInt(hex.substring(2, 4), 16);
//   let b = parseInt(hex.substring(4, 6), 16);

//   // Return the RGB color as an object
//   return `rgba(${r}, ${g}, ${b}, ${alpha})`; // Fixed missing closing parenthesis
// };
//this checks to see if the string is basically the same as the other string for drawing lines
const isSubstring = (table, column) => {
  if (column.includes(table.slice(0, -1))) {
    return true;
  }
  return false;
};

function removeDuplicateIds(array) {
  const uniqueIds = new Set();
  return array.filter((obj) => {
    if (!uniqueIds.has(obj.id)) {
      uniqueIds.add(obj.id);
      return true;
    }
    return false;
  });
}

const foreignKeys = (data) => {
  return data[0].Data.map((table) => {
    //only include the columns that link out
    return {
      ...table,
      columns: table.columns.filter(
        (column) => column.label.includes("ID") && column.name !== "id"
      ),
    };
  })
    .filter((table) => table.columns.length > 0)
    .map((table) => {
      //build a new dataset that looks for matching tables
      return {
        ...table,
        columns: table.columns.map((column) => {
          //check if there is a match on the column
          return {
            ...column,
            ref: data[0].Data.filter((table) =>
              isSubstring(table.title, column.label)
            )[0]
              ? data[0].Data.filter((table) =>
                  isSubstring(table.title, column.label)
                )[0].id
              : null,
          };
        }),
      };
    })
    .flatMap((table) => {
      //build the actual line list now
      return table.columns.map((column) => {
        return {
          id: `${table.id}-${column.name}-${column.ref}`,
          source: table.id,
          target: column.ref,
          sourceHandle: `source-${table.id}-${column.name}`,
          targetHandle: column.ref ? `target-${column.ref}-id` : null,
        };
      });
    })
    .filter((line) => line.source && line.target);
};

const LayoutFlow = () => {
  const { data, loading, error } = useCdm();
  const { fitView, getNodes } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const nodesInitialized = useNodesInitialized();
  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    if (data && !loading && !error) {
      // Perform operations on the data once it's available
      //let uniqueStereotypes = [...new Set(data[0].Data.map(obj => obj.stereotype))];
      let uniqueStereotypes = [
        "Physical Artifact",
        "Operational Artifact",
        "Reference",
        "Log",
      ];
      let dataNodes = data[0].Data.map((table, index) => ({
        id: table.id,
        data: table,
        position: { x: 100, y: 100 },
        type: "tableNode",
      }));
      setNodes(dataNodes);
      let newNodes = makeLayout(getNodes());
      let newEdges = foreignKeys(data);
      setEdges(removeDuplicateIds(newEdges));
    }
  }, [data]);

  useEffect(() => {
    if (nodes.length == 0) {
      return;
    }
    let prevNodes = getNodes();

    let transformed = prevNodes.map((node) => {
      updateNodeInternals(node.id);
      return {
        width: node.width,
        height: node.height,
        id: node.id,
        stereotype: node.data.stereotype,
      };
    });
    let summary = transformed.reduce((result, obj) => {
      const { height, stereotype, id } = obj;

      // Update max height for the stereotype
      if (!result[stereotype]) {
        result[stereotype] = {
          maxHeight: height,
          cumulative: height,
          count: 1,
          ids: [id],
        };
      } else {
        result[stereotype].maxHeight = Math.max(
          result[stereotype].maxHeight,
          height
        );
        result[stereotype].count++;
        result[stereotype].ids.push(id);
      }

      return result;
    }, {});
    let cumulativeHeight = 0;
    for (const stereotype in summary) {
      summary[stereotype].cumulative = cumulativeHeight;
      cumulativeHeight += summary[stereotype].maxHeight + 100;
    }

    let prevX = 0;
    let prev;
    let newNodes = prevNodes.map((item, index) => {
      let indexOf = summary[item.data.stereotype].ids.indexOf(item.id);
      let newY = summary[item.data.stereotype].cumulative;
      let newX = (item.width + 100) * indexOf;
      return {
        ...item,
        position: {
          ...item.position,
          x: newX,
          y: newY,
        },
      };
    });
    setNodes(newNodes);

    window.requestAnimationFrame(() => {
      fitView();
    });
  }, [nodesInitialized]);

  return (
    <div style={{width:"100%", height:"100vh"}}>
<ReactFlow
      nodes={nodes}
      onNodesChange={onNodesChange}
      edges={edges}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      fitView
    >
      <Background />
      <Controls />
    </ReactFlow>
    </div>
    
  );
};
export default function CommonDataModel() {
  return (
    <div style={{ height: "calc(100% - 0px)" }}>
      <ReactFlowProvider>
        <LayoutFlow></LayoutFlow>
      </ReactFlowProvider>
    </div>
  );
}
