import React, { useRef, useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import { Html } from '@react-three/drei';
import * as THREE from 'three';

// Import a FallbackBox component (assumed to exist elsewhere in your project)
// If it doesn't exist, you'll need to create this component
import FallbackBox from './FallbackBox';
import ExclamationMark from './ExclamationMark';



// Complete machine group with model and alert elements
const MachineGroup = ({ position, rotation, showAlert, id }) => {
  const groupRef = useRef();
  // Use unique keys for each machine to force separate instances
  const { scene } = useGLTF('/render/model.glb', true);
  const [modelReady, setModelReady] = useState(false);
  const [modelSize, setModelSize] = useState({ x: 1, y: 1, z: 1 });
  const [modelOffset, setModelOffset] = useState({ x: 0, y: 0, z: 0 });
  const [clonedScene, setClonedScene] = useState(null);
  
  // Process the model when it's loaded
  useEffect(() => {
    if (!scene) return;
    
    try {
      // Clone the scene for this instance to avoid sharing
      const newScene = scene.clone(true);
      setClonedScene(newScene);
      
      // Calculate the bounding box to get the model size and center
      const bbox = new THREE.Box3().setFromObject(newScene);
      const size = new THREE.Vector3();
      bbox.getSize(size);
      
      // Set the model size for the outline
      setModelSize({ x: size.x, y: size.y, z: size.z });
      
      // Calculate x and z offsets (keep y as is for correct height)
      setModelOffset({ x: size.x/1.7, y: 0, z: -size.z/1.5 });
      
      // Enable shadows for all meshes in the model
      newScene.traverse((child) => {
        if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;
          
          if (child.material) {
            // Clone materials to avoid sharing between instances
            child.material = child.material.clone();
            child.material.colorSpace = THREE.SRGBColorSpace;
            child.material.needsUpdate = true;
          }
        }
      });
      
      setModelReady(true);
    } catch (error) {
      console.error("Error processing model:", error);
    }
  }, [scene]);
  
  return (
    <group ref={groupRef} position={position} rotation={rotation}>
      {/* The model itself with x,z offset correction */}
      {modelReady && clonedScene ? (
        <primitive 
          object={clonedScene} 
          position={[modelOffset.x, modelOffset.y, modelOffset.z]} 
        />
      ) : (
        <FallbackBox position={[0, 0, 0]} />
      )}
      
      {/* Alert elements - only shown when alert is active */}
      {showAlert && modelReady && (
        <>
          <ExclamationMark height={modelSize.y} width={modelSize.x} />
        </>
      )}
      
      {/* Machine ID label */}
      <Html position={[modelSize.x*-.25, modelSize.y*1, 0]} center>
        <div style={{ 
          backgroundColor: 'rgba(0,0,0,0.7)', 
          color: 'white', 
          padding: '4px 8px', 
          borderRadius: '4px',
          fontSize: '12px'
        }}>
          Machine {id}
        </div>
      </Html>
    </group>
  );
};

export default MachineGroup;