import { useState } from "react";
import { useAuth } from "./useAuth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/config";
import { useDocs } from "./useDocs";
import { useApp } from "./useApp";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatchUser } = useAuth();
  const { getUserDeets, getPrivateAccount, getAdminRecord, addPrivateAccount } =
    useDocs("partnerDetails");
  const { setPartnerTheme, setSvgLogo } = useApp();
  const loginEmail = async (email, password) => {
    debugger
    setError(null);
    setIsPending(true);
    //sign the user out
    try {
      //sign in user
      //const pers = await setPersistence(auth, browserSessionPersistence)
      const res = await signInWithEmailAndPassword(auth, email, password);

      if (!res) {
        throw new Error("could not complete signup");
      }
      //signed in
      setIsPending(false);
      setError(null);
      const deets = await getUserDeets(res.user.uid);
      const domain = extractDomain(res.user.email) ?? null;
      let emailSuffix = "";
      let privateAccount = null;
      let approved = false;
      let privateAccountData = await getPrivateAccount(domain);
      debugger
      if (privateAccountData) {
        if (
          privateAccountData.hasOwnProperty("isApproved") &&
          privateAccountData.isApproved === true
        ) {
          if (privateAccountData.theme) {
            setPartnerTheme(privateAccountData?.theme);
            const theme = {
              primaryColor: privateAccountData?.theme?.primaryColor,
              bgColor: privateAccountData?.theme?.bgColor,
              highlight: privateAccountData?.theme?.highlight,
            };
            localStorage.setItem("theme", JSON.stringify(theme));
          }
          if (privateAccountData.svg) {
            setSvgLogo(privateAccountData.svg);
            localStorage.setItem("logo", privateAccountData.svg);
          }
          emailSuffix = domain;
          privateAccount = privateAccountData;
          approved = true;
        }
      } else {
        await addPrivateAccount(domain, {name: "", svg: "", isApproved: false});
      }

      let admin = await getAdminRecord(res.user);

      dispatchUser({
        type: "LOGIN",
        payload: {
          ...res.user,
          approved: approved,
          domain: emailSuffix,
          privateAccount: privateAccount,
          defaultView: deets?.defaultView ?? null,
          votes: deets?.votes ?? [],
          views: deets?.views ?? [],
          userEmailVerified: deets?.emailVerified,
          admin: admin ?? false,
        },
      });
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };
  const extractDomain = (email) => {
    const domain = email.split("@")[1];
    return domain || null;
  };
  return { loginEmail, loginError: error, loginPending: isPending };
};
