import React, { useEffect, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useGLTF } from '@react-three/drei';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

// VehicleCharacter component - handles a vehicle with an optional driver
const VehicleCharacter = ({ 
  id, // Unique identifier for the vehicle
  vehicleModel, // Path to the vehicle GLB model
  position = [0, 0, 0],  
  rotation = [0, 0, 0],
  scale = 1,
  // Driver specific props
  driverModel = null, // Path to driver FBX model (optional)
  driverPosition = [0, 0, 0], // Relative position of driver within vehicle
  driverRotation = [0, 0, 0], // Relative rotation of driver
  driverScale = 0.01, // Scale of driver model
  // Action definitions
  actions = {}, // Object containing predefined actions (sequences of movements)
  // Common props
  onActionComplete = () => {}, // Callback when an action is completed
}, ref) => {
  const group = useRef(); // Root group for the entire vehicle
  const vehicleRef = useRef(); // Reference to the vehicle model
  const driverRef = useRef(); // Reference to the driver model
  const driverMixer = useRef(null); // Animation mixer for the driver
  const fbxLoader = new FBXLoader(); // Create FBX loader instance directly
  
  // Movement state
  const vehiclePosition = useRef(new THREE.Vector3(...position));
  const targetPosition = useRef(null);
  const isMoving = useRef(false);
  const rotationSpeed = 0.1;
  const movementSpeed = 0.05;
  
  // Action queue and current action tracking
  const actionQueue = useRef([]);
  const currentAction = useRef(null);
  const actionStep = useRef(0);
  const isExecutingAction = useRef(false);
  
  // Load the vehicle GLB model
  const { scene: vehicleScene } = useGLTF(vehicleModel, true);
  
  // Setup vehicle and driver in a single useEffect
  useEffect(() => {
    // Setup vehicle if not already set up
    if (vehicleScene && !vehicleRef.current) {
      const vehicle = vehicleScene.clone();
      vehicleRef.current = vehicle;
      
      // Apply scale to the vehicle
      vehicle.scale.set(scale, scale, scale);
      
      // Configure shadows for all meshes in the model
      vehicle.traverse((child) => {
        if (child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = true;
        }
      });
      
      // Add to the group
      group.current.add(vehicle);
      console.log(`${id}: Vehicle loaded at scale ${scale}`);
    }
    
    // Setup driver if provided and not already loaded
    if (driverModel && !driverRef.current) {
      console.log(`${id}: Loading driver model from ${driverModel}`);
      
      fbxLoader.load(
        driverModel,
        (fbx) => {
          console.log(`${id}: Driver model loaded successfully`);
          driverRef.current = fbx;
          
          // Create animation mixer for the driver
          driverMixer.current = new THREE.AnimationMixer(fbx);
          
          // Configure driver positioning and shadows
          fbx.position.set(driverPosition[0], driverPosition[1], driverPosition[2]);
          fbx.rotation.set(driverRotation[0], driverRotation[1], driverRotation[2]);
          fbx.scale.set(driverScale, driverScale, driverScale);
          
          fbx.traverse((child) => {
            if (child.isMesh) {
              child.castShadow = true;
              child.receiveShadow = true;
            }
          });
          
          // Add driver to vehicle
          if (vehicleRef.current) {
            vehicleRef.current.add(fbx);
            console.log(`${id}: Driver added to vehicle at position`, fbx.position);
          } else {
            console.error(`${id}: Vehicle ref not found when adding driver`);
          }
          
          // Setup driver animation
          if (fbx.animations && fbx.animations.length > 0) {
            // Log available animations
            fbx.animations.forEach((clip, index) => 
              console.log(`${id}: Animation ${index}: Name: ${clip.name}, Duration: ${clip.duration}`)
            );
            
            // Use the first animation
            const clip = fbx.animations[0];
            const action = driverMixer.current.clipAction(clip);
            action.loop = THREE.LoopRepeat;
            action.play();
            console.log(`${id}: Playing driver animation '${clip.name}'`);
          } else {
            console.warn(`${id}: No animations found in driver FBX`);
          }
        },
        (xhr) => console.log(`${id}: Driver ${Math.round((xhr.loaded / xhr.total) * 100)}% loaded`),
        (error) => console.error(`${id}: Error loading driver:`, error)
      );
    }
    
    // Cleanup function
    return () => {
      if (driverMixer.current) {
        driverMixer.current.stopAllAction();
      }
    };
  }, [vehicleScene, driverModel, scale, driverPosition, driverRotation, driverScale, id]);
  
  // Method to execute a step in the current action
  const executeActionStep = () => {
    if (!currentAction.current || actionStep.current >= currentAction.current.steps.length) {
      // Action complete, reset
      resetToDefaultState();
      return;
    }
    
    const step = currentAction.current.steps[actionStep.current];
    
    // Execute the step based on its type
    if (step.type === 'move') {
      // Move to position
      driveTo(step.target);
      // No need to increment step here - it will be incremented when movement completes
    } else if (step.type === 'rotate') {
      // Rotate to angle
      rotateTo(step.angle);
      // Increment after starting rotation
      actionStep.current++;
      executeActionStep();
    } else if (step.type === 'wait') {
      // Just wait for specified duration
      setTimeout(() => {
        actionStep.current++;
        executeActionStep();
      }, step.duration || 1000);
    }
  };
  
  // Reset state when action is complete
  const resetToDefaultState = () => {
    isExecutingAction.current = false;
    
    // Call the onActionComplete callback
    onActionComplete(id, currentAction.current?.name);
    
    // Check if there are more actions in the queue
    if (actionQueue.current.length > 0) {
      // Get the next action from the queue and execute it
      const nextAction = actionQueue.current.shift();
      executeAction(nextAction);
    } else {
      currentAction.current = null;
    }
  };
  
  // Queue an action for execution
  const queueAction = (actionName) => {
    const action = actions[actionName];
    if (!action) {
      console.error(`${id}: No action found with name: ${actionName}`);
      return;
    }
    
    actionQueue.current.push({...action, name: actionName});
    
    // If not currently executing an action, start this one
    if (!isExecutingAction.current) {
      const nextAction = actionQueue.current.shift();
      executeAction(nextAction);
    }
  };
  
  // Execute a specific action
  const executeAction = (action) => {
    if (!action) return;
    
    console.log(`${id}: Executing action: ${action.name}`);
    
    // Set current action and reset step counter
    currentAction.current = action;
    actionStep.current = 0;
    isExecutingAction.current = true;
    
    // Begin executing steps
    executeActionStep();
  };
  
  // Update position and rotation of vehicle, and driver animation
  useFrame((_, delta) => {
    // Update driver animation mixer if exists
    if (driverMixer.current) {
      driverMixer.current.update(delta);
    }
    
    // Skip if not moving or no target
    if (!isMoving.current || !targetPosition.current) return;
    
    // Calculate direction to target
    const direction = new THREE.Vector3()
      .subVectors(targetPosition.current, vehiclePosition.current)
      .setY(0);
      
    // Calculate distance to target
    const distance = direction.length();
    
    // If we're close enough to the target, stop moving
    if (distance < 0.1) {
      isMoving.current = false;
      
      // If executing an action, move to next step
      if (isExecutingAction.current) {
        actionStep.current++;
        executeActionStep();
      }
      return;
    }
    
    // Normalize the direction
    direction.normalize();
    
    // Calculate the target rotation
    const targetRotation = Math.atan2(direction.x, direction.z);
    
    // Get current rotation
    let currentRotation = group.current.rotation.y;
    
    // Handle rotation wrapping for smooth transitions
    const rotationDiff = targetRotation - currentRotation;
    if (rotationDiff > Math.PI) currentRotation += 2 * Math.PI;
    if (rotationDiff < -Math.PI) currentRotation -= 2 * Math.PI;
    
    // Use THREE.MathUtils.lerp for smoother rotation
    currentRotation = THREE.MathUtils.lerp(currentRotation, targetRotation, rotationSpeed);
    
    // Apply rotation
    group.current.rotation.y = currentRotation;
    
    // Move in the direction we're facing
    const moveDistance = Math.min(movementSpeed * delta * 60, distance);
    const movement = direction.multiplyScalar(moveDistance);
    
    vehiclePosition.current.add(movement);
    group.current.position.copy(vehiclePosition.current);
  });
  
  // Vehicle movement methods
  const driveTo = (target) => {
    targetPosition.current = new THREE.Vector3(target.x, target.y || 0, target.z);
    isMoving.current = true;
  };
  
  const stopDriving = () => {
    isMoving.current = false;
  };
  
  const rotateTo = (angle) => {
    group.current.rotation.y = angle;
  };
  
  const getCurrentPosition = () => {
    return vehiclePosition.current.clone();
  };
  
  // Animation control for testing
  const playAnimationByIndex = (index) => {
    if (driverMixer.current && driverRef.current?.animations?.[index]) {
      driverMixer.current.stopAllAction();
      const clip = driverRef.current.animations[index];
      const action = driverMixer.current.clipAction(clip);
      action.loop = THREE.LoopRepeat;
      action.play();
      console.log(`${id}: Playing animation '${clip.name}' (index ${index})`);
    }
  };

  // Expose methods to parent component
  React.useImperativeHandle(ref, () => ({
    driveTo,
    stopDriving,
    rotateTo,
    getCurrentPosition,
    isMoving: () => isMoving.current,
    queueAction,
    executeAction: (actionName) => {
      const action = actions[actionName];
      if (action) {
        executeAction({...action, name: actionName});
      }
    },
    getId: () => id,
    isExecutingAction: () => isExecutingAction.current,
    getCurrentAction: () => currentAction.current?.name,
    playAnimationByIndex, // For testing animations
  }));

  return (
    <group 
      ref={group} 
      position={position}
      rotation={rotation}
    />
  );
};

export default React.forwardRef(VehicleCharacter);