import { useReducer, useEffect, useState } from "react";
import { db } from "../firebase/config";
import {
  collection,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  onSnapshot,
  getDoc,
  writeBatch,
  serverTimestamp,
  query,
  where,
  getDocs,
  updateDoc
} from "firebase/firestore";
import { useCollection } from "./useCollection";

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const firestoreReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { document: null, success: null, error: null, isPending: true };
    case "ADDED_DOCUMENT":
      return {
        isPending: false,
        document: action.payload,
        success: true,
        error: null,
      };
    case "ERROR":
      return {
        isPending: false,
        document: null,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useDocs = (collectionid) => {
  const [response, dispatch] = useReducer(firestoreReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);
  const [mydoc, setMydoc] = useState(null);
  const [error, setError] = useState(null);
  const [mydocPending, setMydocPending] = useState(false);
  const { documents: vtecAssetsAll } = useCollection("vtec_assets");

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action);
    }
  };

  const deleteAsset = async (asset) => {
    try {
      const documentRef = doc(db, "vtec_assets", asset.id);
      await deleteDoc(documentRef);
      console.log("deleted");
    } catch (err) {
      console.log(err.message);
    }
  };

  const addDocument = async (newdoc, user) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await addDoc(collection(db, "vtec_assets"), {
        ...newdoc,
        userid: user.uid,
        email: user.email,
        createdAt: serverTimestamp(),
      });

      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log(addedDocument);
      return addedDocument;
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(err.message);
      return null;
    }
  };

  const addSeed = async (newdoc, user, meta) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await addDoc(collection(db, "seeds"), {
        seedData: newdoc,
        userid: user.uid,
        email: user.email,
        title: meta.title,
        desc: meta.desc,
        imgurl: meta.imgurl,
        createdAt: serverTimestamp()
      });

      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log(addedDocument);
      return addedDocument;
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(err.message);
      return null;
    }
  };

  const updatePosition = async (asset) => {
    dispatch({ type: "IS_PENDING" }); // Assuming you're using Redux
    try {
      const addedDocument = await setDoc(
        doc(db, "vtec_assets", asset.id),
        { Top: asset.Top, Left: asset.Left },
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      }); // Assuming you're using Redux
      console.log("Document updated successfully");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message }); // Assuming you're using Redux
      console.error("Failed to update document:", err);
    }
  };

  const updateAsset = async (asset) => {
    dispatch({ type: "IS_PENDING" }); // Assuming you're using Redux
    try {
      const { id, ...rest } = asset;
      const addedDocument = await setDoc(
        doc(db, "vtec_assets", asset.id),
        rest,
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      }); // Assuming you're using Redux
      console.log("Document updated successfully");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message }); // Assuming you're using Redux
      console.error("Failed to update document:", err);
    }
  };

  const setDocument = async (vtecAsset, data) => {
    dispatch({ type: "IS_PENDING" });
    console.log(vtecAsset.id);
    try {
      const addedDocument = await setDoc(
        doc(db, "vtec_assets", vtecAsset.id),
        data,
        { merge: true }
      );

      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };
  const getUserDeets = async (userId) => {
    try {
      let ref = doc(db, "partnerDetails", userId);
      const docSnap = await getDoc(ref);
      console.log(docSnap);
      return docSnap.data();
    } catch (err) {
      return { approved: false, partnerAccount: null, privateId: null };
    }
  };
  const setUserDeets = async (userId, email, emailVerified) => {
    dispatch({ type: "IS_PENDING" });
    console.log("setting the user");
    console.log(userId);
    let isApproved = false;
    try {
      const domain = extractDomain(email);
      const privateAccount = domain ? await getPrivateAccount(domain) : null;
      if(privateAccount){
        if (privateAccount.hasOwnProperty("isApproved")) {
          isApproved = privateAccount.isApproved;
        }
      }
      else{
        await addPrivateAccount(domain, {name: "", svg: "", isApproved: false});
      }
      
      const addedDocument = await setDoc(
        doc(db, "partnerDetails", userId),
        {
          approved: isApproved,
          partnerAccount: null,
          email: email,
          privateId: null,
          emailVerified: emailVerified,
        },
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };
  const extractDomain = (email) => {
    const domain = email.split("@")[1];
    return domain || null;
  };
  const deletePartner = async (partner) => {
    try {
      const documentRef = doc(db, "partnerDetails", partner.id);
      await deleteDoc(documentRef);
      console.log("deleted");
    } catch (err) {
      console.log(err.message);
    }
  };

  const deleteSeed = async (seed) => {
    try {
      const documentRef = doc(db, "seeds", seed.id);
      await deleteDoc(documentRef);
      console.log("deleted");
    } catch (err) {
      console.log(err.message);
    }
  };


  const ReadDocument = (collectionId, docId) => {
    //fires once and whenever the collection changes
    useEffect(() => {
      setMydocPending(true);
      //set up a real time listener to a firestore collection
      let ref = doc(db, collectionid, docId);
      const unsubscribe = onSnapshot(
        ref,
        (snapshot) => {
          if (snapshot.exists()) {
            console.log("exists");
            setMydoc(snapshot.data());
          } else {
            console.log("doc doesnt exist");
            // setOpportunity(docId, null, []);
          }
          setMydocPending(false);
          setError(null);
        },
        (error) => {
          console.log(error);
          setMydocPending(false);
          setError("could not fetch the doc");
        }
      );
      //unsub on unmount
      return () => unsubscribe();
    }, [collectionId, docId]);
    return { mydoc, error, mydocPending };
  };
  //for updating partner accounts and parterns
  const updateDocument = async (id, obj, collectionType) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await setDoc(doc(db, collectionType, id), obj, {
        merge: true,
      });
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.error(err); // Log the entire error object for better debugging
    }
  };
  const updateMultipleDocuments = async (updates) => {
    const batch = writeBatch(db); // Initialize the batch
    // Iterate over the updates array and add each update to the batch
    updates.forEach(({ collection, id, data }) => {
      const docRef = doc(db, collection, id);
      batch.update(docRef, data);
    });
    dispatch({ type: "IS_PENDING" });
    try {
      await batch.commit(); // Commit the batch
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: updates, // You can customize the payload as needed
      });
      console.log("Documents updated successfully");
    } catch (error) {
      dispatchIfNotCancelled({
        type: "ERROR",
        payload: error.message,
      });
      console.error("Error updating documents: ", error);
    }
  };

  const setEmailVerified = async (userId, emailVerified) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await setDoc(
        doc(db, "partnerDetails", userId),
        {
          emailVerified: emailVerified,
        },
        { merge: true }
      );
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };
  const addViewDocument = async (newdoc) => {
    const { id, ...rest } = newdoc;
    dispatch({ type: "IS_PENDING" });
    try {
      const collectionRef = collection(db, "views");
      if (newdoc.isAdminDefault) {
        const q = query(collectionRef, where("isAdminDefault", "==", true));
        const querySnapshot = await getDocs(q);

        const batch = writeBatch(db);
        querySnapshot.forEach((doc) => {
          const docRef = doc.ref;
          if (doc.id !== id) {
            // Exclude the document we're currently saving or updating
            batch.update(docRef, { isAdminDefault: false });
          }
        });
        await batch.commit();
      }
      const docRef = id ? doc(db, "views", id) : collectionRef;

      const addedDocument = id
        ? await setDoc(docRef, { ...rest, createdAt: serverTimestamp() })
        : await addDoc(docRef, { ...rest, createdAt: serverTimestamp() });

      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log(addedDocument);
      return addedDocument;
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(err.message);
      return null;
    }
  };
  const deleteDocument = async (id, collection) => {
    try {
      const documentRef = doc(db, collection, id);
      await deleteDoc(documentRef);
      console.log("deleted");
    } catch (err) {
      console.log(err.message);
    }
  };
  const addViewsToPartner = async (data, userId) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const addedDocument = await setDoc(doc(db, "partnerDetails", userId), data, {
        merge: true,
      });
      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: addedDocument,
      });
      console.log("done");
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.log(`failed to write ${err}`);
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, [mydoc]);

  const addPrivateAccount = async (id, newdoc) => {
    dispatch({ type: "IS_PENDING" });
    try {
      let documentRef;
      if (id) {
        documentRef = doc(db, "privateAccounts", id);
        await setDoc(documentRef, {
          ...newdoc,
          createdAt: serverTimestamp(),
        });
      } else {
        documentRef = await addDoc(collection(db, "privateAccounts"), {
          ...newdoc,
          createdAt: serverTimestamp(),
        });
      }

      dispatchIfNotCancelled({
        type: "ADDED_DOCUMENT",
        payload: { id: documentRef.id, ...newdoc },
      });
      return documentRef;
    } catch (err) {
      dispatchIfNotCancelled({ type: "ERROR", payload: err.message });
      console.error("Error adding document:", err.message);
      return null;
    }
  };

  const getPrivateAccount = async (emailSuffix) => {
    try {
      let ref = doc(db, "privateAccounts", emailSuffix);
      const docSnap = await getDoc(ref);
      if (!docSnap.empty) {
        console.log(docSnap);
        return docSnap.data();
      }
      const collRef = collection(db, "privateAccounts");
      const linkedAccountsQuery = query(
        collRef,
        where("childEmails", "array-contains", emailSuffix)
      );
      const linkedAccountsQuerySnapshot = await getDocs(linkedAccountsQuery);

      if (!linkedAccountsQuerySnapshot.empty) {
        return linkedAccountsQuerySnapshot.data();
      }
    } catch (err) {
      console.log(err, emailSuffix);
      return { svg: null };
    }
  };
  const updateUnapprovedUsers = async (partnerAccount, isApproved) => {
    try {
        const usersRef = collection(db, "partnerDetails"); 
        
        // Firestore query to fetch only unapproved users
        const q = query(usersRef, 
            where("approved", "==", isApproved ? false : true)
        );

        const querySnapshot = await getDocs(q);
        const batchUpdates = [];

        querySnapshot.forEach((doc) => {
            const userData = doc.data();
            if (userData.email.endsWith(partnerAccount)) {
                batchUpdates.push(updateDoc(doc.ref, { approved: isApproved }));
            }
        });

        await Promise.all(batchUpdates);
        console.log("Updated users successfully.");
    } catch (error) {
        console.error("Error updating users:", error);
    }
};
const getAdminRecord = async (user) => {
  try {
    let ref = doc(db, "user_admin", user && user.uid ? user.uid : "empty");
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      const result = docSnap.data();
      return result.admin;
    } else {
      if (user && user.uid) {
        await setDoc(
          doc(db, "user_admin", user.uid),
          { admin: false },
          { merge: true }
        );
      }
      return false;
    }
  } catch (error) {
    console.log(`failed to get admin record ${error}`);
    return false;
  }
};
  return {
    addDocument,
    deleteDocument,
    deletePartner,
    setDocument,
    updateAsset,
    deleteAsset,
    response,
    ReadDocument,
    getUserDeets,
    setUserDeets,
    mydoc,
    deleteSeed,
    addSeed,
    updatePosition,
    updateDocument,
    updateMultipleDocuments,
    setEmailVerified,
    addViewDocument,
    addViewsToPartner,
    addPrivateAccount,
    getPrivateAccount,
    updateUnapprovedUsers,
    getAdminRecord
  };
};
