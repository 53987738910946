import {
  TextField,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ThemeSelector from "./ThemeSelector";
import AddChildEmails from "../../components/AddChildEmails";

export default function PrivateAccountForm({
  selectedRow,
  handleChange,
  handleColorChange,
  isDefaultChecked,
  handleCheckboxChange,
  handleSave,
  setOpenDialog,
  user,
  selectedTheme,
  previewTheme
}) {
  return (
    <div>
      {Object.entries(selectedRow).map(([key, value]) => (
        <div key={key}>
          {key !== "theme" && key !== "childEmails" ? (
            !Array.isArray(value) && key !== "isApproved" ? (
              <TextField
                label={camelToTitleCase(key)}
                name={key}
                value={value || ""}
                fullWidth
                size="small"
                variant="outlined"
                margin="normal"
                disabled={
                  !user.admin &&
                  (key === "id" || key === "name")
                }
                onChange={handleChange}
              />
            ) : user?.admin ? (
              <FormControlLabel
                control={<Checkbox />}
                label="Is Approved"
                name={key}
                checked={value}
                onChange={handleChange}
              />
            ) : null
          ) : key === "childEmails" && user?.admin ? (
            <AddChildEmails
              selectedRow={selectedRow}
              setSelectedRow={(updatedRow) =>
                handleChange({
                  target: {
                    name: "childEmails",
                    value: updatedRow.childEmails,
                  },
                })
              }
            />
          ) : key === "theme" && (
            <ThemeSelector
              handleColorChange={handleColorChange}
              isDefaultChecked={isDefaultChecked}
              handleCheckboxChange={handleCheckboxChange}
              selectedTheme={selectedTheme}
              previewTheme={previewTheme}
            />
          )}
        </div>
      ))}
      {selectedRow && (
        <Stack
          direction="row"
          spacing={2}
          padding={1}
          justifyContent="space-between"
        >
          {user?.admin && selectedRow.id && (
            <Button
              color="error"
              variant="contained"
              onClick={() => setOpenDialog(true)}
            >
              Delete
            </Button>
          )}
          <Button color="success" variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      )}
    </div>
  );
}

function camelToTitleCase(camelCaseString) {
  return camelCaseString
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}
