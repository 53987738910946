import { useState } from "react";
import { useApp } from "./useApp";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";
import { useAuth } from "./useAuth";

export const useSegment = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { selectedAsset } = useApp();
  const { user } = useAuth();

  const trackEvent = async (eventName) => {
    setError(null);
    setIsPending(true);

    try {
      //signup user
      const res = await httpsCallable(
        functions,
        "track_event"
      )({
        event: eventName,
        deets: { ...selectedAsset, opportunityId: user?.privateAccount?.name ?? "Tulip"},
      });
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };

  const trackDirectEvent = async (eventName, asset, accountName) => {
    setError(null);
    setIsPending(true);
    console.log(asset, accountName);
    try {
      //signup user
      const res = await httpsCallable(
        functions,
        "track_event"
      )({
        event: eventName,
        deets: { ...asset, opportunityId: accountName },
      });
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };

  const trackVOC = async (notes) => {
    setError(null);
    setIsPending(true);

    try {
      //signup user
      const res = await httpsCallable(
        functions,
        "track_voc"
      )({
        event: "voc_ai",
        deets: { opportunityId: "opportunityId", notes: notes },
      });
      if (!res) {
        throw new Error("could not complete signup");
      }

      console.log(res);
      setIsPending(false);
      setError(null);
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsPending(false);
    }
  };
  return { error, isPending, trackEvent, trackVOC, trackDirectEvent };
};
