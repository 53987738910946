import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

// Exclamation mark for the alert
const ExclamationMark = ({ height, width }) => {
  const groupRef = useRef();
  const bounceHeight = 0.5;
  const bounceSpeed = 1.5;
  
  // Position the exclamation mark above the model
  const baseY = height + 1;
  const baseX = 0;
  
  useFrame((state, delta) => {
    if (groupRef.current) {
      // Bouncing animation
      groupRef.current.position.y = baseY + Math.abs(Math.sin(state.clock.elapsedTime * bounceSpeed)) * bounceHeight;
      
      // Rotation for dynamic effect
      groupRef.current.rotation.y += delta * 2;
    }
  });
  
  return (
    <group ref={groupRef} position={[baseX, baseY, 0]}>
      {/* Exclamation mark stem */}
      <mesh castShadow position={[0,.4,0]}>
        <coneGeometry args={[.3, -1.5]}  />
        <meshStandardMaterial color="red" emissive="#ff0000" emissiveIntensity={0.5} />
        
      </mesh>
      <pointLight 
        position={[.5, -1, 0]} 
        color="red"
        intensity={20} 
        distance={10}
        decay={2}
      />
      {/* Exclamation mark dot */}
      <mesh position={[0, -0.5, 0]} castShadow>
        <sphereGeometry args={[0.15, 16, 16]} />
        <meshStandardMaterial color="red" emissive="red" emissiveIntensity={0.5} />
      </mesh>
    </group>
  );
};

export default ExclamationMark;