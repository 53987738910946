import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Typography, Stack, TextField, Grid } from "@mui/material";

const AddChildEmails = ({selectedRow, setSelectedRow}) => {
  const handleNewChild = (event) => {
    const newChilds = [...selectedRow.childEmails, ""];
    const newAccount = { ...selectedRow, childEmails: newChilds };
    setSelectedRow(newAccount);
  };
  return (
    <>
      <Typography>Child Emails</Typography>
      {selectedRow?.childEmails && selectedRow.childEmails.length > 0 &&
        selectedRow.childEmails.map((child, thisIndex) => (
          <Stack key={thisIndex} direction={"row"} marginBottom={1}>
            <IconButton
              aria-label="delete"
              xs
              onClick={() => {
                const newEmails = selectedRow.childEmails.filter(
                  (item, index) => index !== thisIndex
                );
                setSelectedRow({
                  ...selectedRow,
                  childEmails: newEmails,
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
            <TextField
              key={`feature-${thisIndex}`}
              size="small"
              sx={{ width: "50%" }}
              id={`feature-${thisIndex}`}
              value={child}
              onChange={(e) => {
                const updatedItems = [...selectedRow.childEmails];
                updatedItems[thisIndex] = e.target.value;
                setSelectedRow({
                  ...selectedRow,
                  childEmails: updatedItems,
                });
              }}
            />
          </Stack>
        ))}
      <Grid container>
        <Grid item xs>
          <IconButton
            aria-label="delete"
            xs
            onClick={(event) => {
              handleNewChild(event);
            }}
          >
            <AddCircleIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default AddChildEmails;
