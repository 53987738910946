import { UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStorage } from "../hooks/useStorage";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const ImageUpload = ({ value, onChange }) => {
  const [tab, setTab] = useState("upload");
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(value);
  const [loading, setLoading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const { uploadFile, deleteFile, error } = useStorage();

  useEffect(() => {
    if (value) {
      setTab("url");
      setImageUrl(value);
    }
  }, [value]);
  
  const handleFileChange = async (event) => {
    if(file != null){
      await deleteFile(`assets/${file.name}`);
    }
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setIsUploaded(false)
      //onChange(selectedFile);
    }
  };

  const handleUrlChange = (event) => {
    const url = event.target.value;
    setImageUrl(url);
    onChange(url);
  };

  const handleUpload = async () => {
    if (file === null) {
      return;
    }
    setLoading(true);
    try {
      const url = await uploadFile(file, "assets");
      setImageUrl(url);
      onChange(url);
      setIsUploaded(true)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (file == null && !isUploaded) return;
    try {
      await deleteFile(`assets/${file.name}`);
      setImageUrl("");
      onChange("");
      setFile(null);
      setIsUploaded(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      gap={2}
      border="1px solid #ccc"
      borderRadius="8px"
      width="100%"
      mx="auto"
    >
      <Box
          display="flex"
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <ToggleButtonGroup
            color="primary"
            value={tab}
            exclusive
            onChange={(e, newValue) => setTab(newValue)}
            aria-label="Platform"
          >
            <ToggleButton size="small" value="upload">
              Upload Image
            </ToggleButton>
            <ToggleButton size="small" value="url">
              Enter Path
            </ToggleButton>
          </ToggleButtonGroup>
          {(isUploaded && file != null && tab === "upload") && (
            <IconButton
              aria-label="delete"
              onClick={handleDelete}
              disabled={loading}
            >
              <DeleteIcon fontSize={"small"} />
            </IconButton>
          )}
        </Box>
      
      {tab === "upload" ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"space-between"}
        >
          <Button
            variant="contained"
            component="label"
            startIcon={<UploadFile />}
          >
            Choose Image
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
          {file && (
            <Typography variant="body2" color="textSecondary">
              Selected Image: {file.name}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={loading || !file}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Upload
          </Button>
        </Box>
      ) : (
        <TextField
          label="Image URL"
          variant="outlined"
          fullWidth
          value={imageUrl}
          onChange={handleUrlChange}
          placeholder="Enter image URL"
        />
      )}
    </Box>
  );
};

export default ImageUpload;
