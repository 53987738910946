import { Box, CircularProgress } from "@mui/material";
import React from "react";

const ProgressLoader = () => {
  return (
    <Box style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <CircularProgress size={60} thickness={3} />
      </Box>
    </Box>
  );
};

export default ProgressLoader;
