import React from 'react'
import { Link } from "react-router-dom";
import { ReactComponent as SvgTulip } from "../svgs/tulipwhite.svg";
import {
  Grid,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { useLogout } from "../hooks/useLogout";
import { useApp } from "../hooks/useApp";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { useAdmin } from "../hooks/useAdmin";
import Badge from "@mui/material/Badge";
import Videoplayer from "./Videoplayer";
import RegisterModal from "./RegisterModal";
import LogoMaster from "./LogoMaster";

export default function Navbar({showMenu = true}) {
  const { user } = useAuth();
  const { logout } = useLogout();

  const {
    setActiveVideo,
    setRegister,
    svgLogo
  } = useApp();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { myAdminRecord, updateAdminRecord } = useAdmin();

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const forceClose = () => {
    setAnchorEl(null);
  };

  const handleVideo = (videoUrl) => {
    updateAdminRecord("exampleUsage");
    setActiveVideo(videoUrl);
  };

  const handleRegister = () => {
    updateAdminRecord("registerDevices");
    setRegister(true);
  };

  const countNotifications =
    myAdminRecord && !myAdminRecord.exampleUsage
      ? 1
      : 0 + myAdminRecord && !myAdminRecord.registerDevices
      ? 1
      : 0;
  return (
    <Grid
      container
      justifyContent="space-between"
      className='navStandardGradient w-full px-3 sticky top-0 z-10'
      alignItems={"center"}
      height={65}
    >
      <Grid item sx={{ height: "100%", padding: "12px 0", color: "customColors.white" }}>
        {svgLogo ? <LogoMaster /> : <SvgTulip />}
      </Grid>
      {user && showMenu && (
        <Grid>
          <Grid container alignItems={"center"} gap={1}>
            <Grid item xs={"auto"} justifyItems={"center"} height={"100%"}>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="large"
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  className='!pr-0'
                  aria-expanded={open ? "true" : undefined}
                >
                  <Badge badgeContent={countNotifications} color="error">
                    {user.photoURL && <Avatar src={user.photoURL}></Avatar>}
                    {!user.photoURL && (
                      <Avatar>{user.email.charAt(0).toUpperCase()}</Avatar>
                    )}
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={forceClose}
                onClick={forceClose}
              >
                <MenuItem component={Link} to={"/"}>
                  Home
                </MenuItem>

                <MenuItem component={Link} to={"/create"}>
                  Create App Asset
                </MenuItem>
                <MenuItem
                  color={"red"}
                  onClick={() => {
                    handleRegister();
                  }}
                >
                  <Typography
                    color={
                      myAdminRecord && !myAdminRecord.registerDevices
                        ? "error"
                        : "standard"
                    }
                  >
                    Register Devices
                  </Typography>
                </MenuItem>
                <MenuItem
                  color={"red"}
                  onClick={() => {
                    handleVideo(
                      "https://tulip.widen.net/view/video/zphw9fhllc/tulip-overview.mp4"
                    );
                  }}
                >
                  <Typography
                    color={
                      myAdminRecord && !myAdminRecord.exampleUsage
                        ? "error"
                        : "standard"
                    }
                  >
                    Example Usage
                  </Typography>
                </MenuItem>
                {myAdminRecord && myAdminRecord.admin && (
                  <MenuItem component={Link} to={"/users"}>
                    Users
                  </MenuItem>
                )}
                {((myAdminRecord && myAdminRecord.admin) || (user && user.domain)) && (
                  <MenuItem component={Link} to={"/private-accounts"}>
                    Private Accounts
                  </MenuItem>
                )}
                <MenuItem component={Link} to={"/assets"}>
                    Manage Assets
                  </MenuItem>
                <MenuItem component={Link} to={"/factoryedit"}>
                    Factory Edit
                  </MenuItem>
                
                <MenuItem component={Link} to={"/factorycreate"}>
                  Factory Create
                </MenuItem>
                <MenuItem component={Link} to={"/manage-views"}>
                  Manage Views
                </MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
              <Videoplayer />
              <RegisterModal />
            </Grid>
          </Grid>
        </Grid>
      )}
   
    </Grid>
  );
}
