import { useState, useCallback, useMemo } from "react";
import {
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Navbar from "../../components/Navbar";
import PreviewTheme from "../../components/theme/PreviewTheme";
import { useCollection } from "../../hooks/useCollection";
import { useDocs } from "../../hooks/useDocs";
import { useApp } from "../../hooks/useApp";
import { useAuth } from "../../hooks/useAuth";
import "./PrivateAccounts.css";
import { emitToast } from "../../common/toastEmitter";
import DialogBox from "../../components/Common/DialogBox";
import PrivateAccountForm from "./PrivateAccountForm";
import SearchBar from "../../components/Common/SearchBar";

const defaultTheme = {
  primaryColor: "#00263e",
  bgColor: "#E7E6DF",
  highlight: "#F2EEA1",
};

const defaultPrivateAccount = {
  id: null,
  name: "",
  svg: "",
  childEmails: [],
  isApproved: false,
  theme: defaultTheme,
};

export default function PrivateAccounts() {
  const { documents } = useCollection("privateAccounts");
  const [selectedRow, setSelectedRow] = useState(null);
  const { addPrivateAccount, deleteDocument, updateUnapprovedUsers } = useDocs();
  const [isDefaultChecked, setIsDefaultChecked] = useState(false);
  const { user } = useAuth();
  const { setPartnerTheme, setSvgLogo } = useApp();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(defaultTheme);
  const [searchText, setSearchText] = useState("");

  const columns = useMemo(
    () => [
      { field: "id", headerName: "Private Id", width: 200 },
      { field: "name", headerName: "Name", width: 200 },
      { field: "approved", headerName: "Approved", width: 80 },
    ],
    []
  );
  const privateIds = useMemo(() => {
    if (documents) {
      let processedData = [];
      if (!user?.admin) {
        processedData = documents
          .filter((item) => item.id === user.domain)
          .map((item) => ({
            id: item.id,
            name: item.name,
            approved: item.isApproved ?? false,
          }));
      } else {
        processedData = documents.map((item) => ({
          id: item.id,
          name: item.name,
          approved: item.isApproved ?? false,
        }));
      }
      if (searchText) {
        return processedData.filter(
          (item) =>
            item.id.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase())
        );
      }
      return processedData;
    }
    return [{ id: "loading", name: "loading", approved: false }];
  }, [documents, searchText]);

  const handleColorChange =(event, colorType) => {
    setSelectedRow((prev) => ({
      ...prev,
      theme: { ...prev.theme, [colorType]: event.target.value },
    }));
    setSelectedTheme({
      ...selectedTheme,
      [colorType]: event.target.value,
    });
    setIsDefaultChecked(false);
  };

  const handleCheckboxChange = useCallback((event) => {
    const isChecked = event.target.checked;
    setIsDefaultChecked(isChecked);
    if (isChecked) {
      setSelectedRow((prev) => ({
        ...prev,
        theme: defaultTheme,
      }));
      setSelectedTheme(defaultTheme);
    }
  }, []);

  const handleCellClick = async (params) => {
    try {
      const privateAccountData = documents.find(
        (item) => item.id === params.row.id
      );
      const theme = {
        primaryColor:
          privateAccountData?.theme?.primaryColor || defaultTheme.primaryColor,
        bgColor: privateAccountData?.theme?.bgColor || defaultTheme.bgColor,
        highlight:
          privateAccountData?.theme?.highlight || defaultTheme.highlight,
      };
      const selectedrowData = {
        id: privateAccountData?.id || params.row.id,
        name: privateAccountData?.name || "",
        svg: privateAccountData?.svg || "",
        childEmails:
          privateAccountData.hasOwnProperty("childEmails") &&
          Array.isArray(privateAccountData?.childEmails)
            ? privateAccountData.childEmails
            : [],
        isApproved: privateAccountData?.isApproved || false,
        theme: theme,
      };
      setSelectedRow(selectedrowData);
      setSelectedTheme(theme);
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  const handleSavePrivateAccount = async () => {
    try {
      if (selectedRow) {
        const newPrivateAccount = {
          name: selectedRow.name,
          svg: selectedRow.svg,
          childEmails: selectedRow.childEmails,
          isApproved: selectedRow.isApproved,
          theme: selectedRow.theme,
        };
        const addedDocument = await addPrivateAccount(
          selectedRow.id,
          newPrivateAccount
        );
        if(user.admin){
          await updateUnapprovedUsers(selectedRow.id, selectedRow.isApproved);
        }
       
        if (addedDocument) {
          emitToast("Private Account saved successfully", "success");
          const userDomain = extractDomain(user.email);
          if (userDomain === addedDocument.id) {
            localStorage.setItem(
              "theme",
              JSON.stringify(newPrivateAccount.theme)
            );
            setPartnerTheme(newPrivateAccount.theme);
            if (selectedRow.svg) {
              setSvgLogo(selectedRow.svg);
              localStorage.setItem("logo", selectedRow.svg);
            }
            else{
              localStorage.removeItem("logo");
            }
           
          }
        }
      }
    } catch (error) {
      emitToast(error, "error");
    }
  };
  const extractDomain = (email) => {
    const domain = email.split("@")[1];
    return domain || null;
  };
  const handleDelete = async () => {
    try {
      if (selectedRow) {
        await deleteDocument(selectedRow.id, "privateAccounts");
        if(user.admin){
          await updateUnapprovedUsers(selectedRow.id, false);
        }
        
        setSelectedRow(null);
        setOpenDialog(false);
        emitToast("Private Account deleted successfully.", "success");
      }
    } catch (error) {
      emitToast("Failed to delete account. Please try again.", "error");
    }
  };
  const handleChange = (event) => {
    setSelectedRow((prev) => ({
      ...prev,
      [event.target.name]:
        event.target.name === "isApproved"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const previewTheme = useMemo(() => {
    return <PreviewTheme partnerTheme={selectedTheme} />;
  }, [selectedTheme]);

  return (
    <>
      <Navbar sx={{ backgroundColor: "grey" }} />

      <Grid
        container
        gap={2}
        height="100%"
        padding={2}
        className="accounts_grid lg:!flex-nowrap"
      >
        <Grid
          item
          xs={12}
          md={4}
          sx={{ height: "calc(100% - 65px)", backgroundColor: "white" }}
          padding={1}
        >
          <Stack direction={"row"} justifyContent={"space-between"} paddingBlock={2}>
            <Typography marginTop={1} fontWeight={600}>
              Private Accounts
            </Typography>
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
          </Stack>
          <DataGrid
            rows={privateIds}
            columns={columns}
            onCellClick={handleCellClick}
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 20 } },
            }}
            pageSizeOptions={[20, 40]}
            checkboxSelection={false}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
          sx={{ height: "calc(100% - 65px)", backgroundColor: "white" }}
          padding={1}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography marginTop={1} fontWeight={600}>
              Selected Private Account
            </Typography>
            {user?.admin && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setSelectedRow(defaultPrivateAccount)}
              >
                Create New
              </Button>
            )}
          </Stack>
          {selectedRow && (
            <PrivateAccountForm
              selectedRow={selectedRow}
              handleChange={handleChange}
              handleColorChange={handleColorChange}
              isDefaultChecked={isDefaultChecked}
              handleCheckboxChange={handleCheckboxChange}
              handleSave={handleSavePrivateAccount}
              setOpenDialog={setOpenDialog}
              user={user}
              selectedTheme={selectedTheme}
              previewTheme={previewTheme}
            />
          )}
        </Grid>
      </Grid>
      <DialogBox
        open={openDialog}
        setOpen={setOpenDialog}
        handleDelete={handleDelete}
        message={"You want to delete the private account?"}
      />
    </>
  );
}
