import { createContext, useReducer } from "react";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/config";
import { useDocs } from "../hooks/useDocs";

export const AuthContext = createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    case "AUTH_IS_READY":
      return { ...state, user: action.payload, authIsReady: true };
    case "VOTE_CHANGE":
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
const extractDomain = (email) => {
  const domain = email.split("@")[1];
  return domain || null;
};
export function AuthProvider({ children }) {
  const { getUserDeets, setUserDeets, setEmailVerified, getPrivateAccount, getAdminRecord } =
    useDocs("partnerDetails");
  const [stateUser, dispatchUser] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      let isApproved = false;
      let partnerAccount = null;
      let domain = null;
      let votes = [];
      let views = [];
      let defaultView = null;
      let privateAccount = null;
      let emailVerified = false;
      let isAdmin = false;
      if (user) {
        getUserDeets(user.uid).then((deets) => {
          if (deets === undefined) {
            deets = {
              approved: false,
              partnerAccount: null,
              domain: null,
              emailVerified: user.emailVerified,
              privateAccount: null,
            };
            setUserDeets(user.uid, user.email, user.emailVerified);
          }
          if (deets?.emailVerified !== true) {
            setEmailVerified(user.uid, user.emailVerified);
            emailVerified = user.emailVerified;
          }
          else {
            emailVerified = true;
          }
          const emailSuffix = extractDomain(user?.email || "");
          getPrivateAccount(emailSuffix).then((res) => {
            if (res && res.hasOwnProperty("isApproved") && res.isApproved) {
              domain = emailSuffix;
              privateAccount = res;
              isApproved = res.isApproved
            }
            if (
              user &&
              user.providerData.length > 0 &&
              user.providerData[0].providerId === "google.com" &&
              user.email.includes("@tulip.co")
            ) {
              partnerAccount = null;
              votes = [];
            } else {
              partnerAccount = deets.partnerAccount ?? null;
              votes = deets?.votes ?? [];
              views = deets?.views ?? [];
              defaultView = deets?.defaultView ?? null;
            }
            getAdminRecord(user).then((response) => {
              isAdmin = response;
              dispatchUser({
                type: "AUTH_IS_READY",
                payload: {
                  ...user,
                  approved: isApproved ?? false,
                  partnerAccount: partnerAccount,
                  domain: domain,
                  votes: votes,
                  views: views,
                  defaultView: defaultView,
                  privateAccount: privateAccount,
                  userEmailVerified : emailVerified,
                  admin: isAdmin
                },
              });
            });
          });
        });
      } else {
        console.log("no user ");
        dispatchUser({ type: "AUTH_IS_READY", payload: null });
      }
      unsub();
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...stateUser,
        dispatchUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
