import React, { useEffect } from "react";
import "./Vtec.css";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/Navbar";
import AssetCard from "../../components/Assetcard";
import Searchpanel from "../../components/Searchpanel";
import Freesolo from "../../components/Freesolo";
import { useApp } from "../../hooks/useApp";
import Assetmodal from "../../components/Assetmodal";
import Ipad from "../composable/Ipad";
import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useDocs } from "../../hooks/useDocs";
import utility from "../../Utils/utility";
import { useCollection } from "../../hooks/useCollection";
import Filter from "../../components/Filter";
import ProgressLoader from "../../components/Common/ProgressLoader";

export default function Vtec({ isPreview = false }) {
  const { searchState, vtecAssets, selectedAsset } = useApp();
  const { updateMultipleDocuments } = useDocs("partnerDetails");
  const { documents: privateAccounts } = useCollection("privateAccounts");
  const theme = useTheme();
  const { user, dispatchUser } = useAuth();
  const [userVotes, setUserVotes] = useState([]);
  const [assets, setAssets] = useState([]);
  const [voteDetails, setVoteDetails] = useState(null);

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (user && user?.votes) {
      setUserVotes(user?.votes);
    }
  }, [isPreview]);

  let assetList = searchState?.filteredAssets || vtecAssets;

  const isWithinLast7Days = (date) => {
    const now = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(now.getDate() - 7);

    return date >= sevenDaysAgo && date <= now;
  };

  useEffect(() => {
    if (Array.isArray(assetList) && assetList.length > 0 && privateAccounts) {
      const initialAssets = assetList.filter(
        (x) => !x.hasOwnProperty("isHidden") || x.isHidden !== true
      );
      const newAssets =
        initialAssets &&
        initialAssets.map((item) => {
          const userVoteObj = userVotes.find((vote) =>
            vote.hasOwnProperty(item.id)
          );
          const userVote = userVoteObj ? userVoteObj[item.id] : null;
          let partnerName = "";
          if (item.privateAccount) {
            const opps = privateAccounts.find(
              (opp) =>
                opp.id === item?.privateAccount && opp.isApproved === true
            );
            if (opps && opps.hasOwnProperty("name")) {
              partnerName = opps.name ?? "Partner";
            }
          }
          let isNew = false;
          if (item?.createdAt) {
            isNew = isWithinLast7Days(item.createdAt.toDate());
          }
          return {
            ...item,
            userVote,
            partnerName,
            isNew,
          };
        });

      setAssets(newAssets);

      // if (Array.isArray(userVotes) && userVotes.length > 0) {
      // } else {
      //   setAssets(initialAssets);

      // }
      setLoader(false);
    }
  }, [privateAccounts, searchState?.filteredAssets]);

  const handleVote = (asset, voteType) => {
    let updateUserVotes;
    let removeVote = false;
    let toggleVote = false;
    setUserVotes((prevVotes) => {
      const existingVoteIndex = prevVotes.findIndex((vote) =>
        vote.hasOwnProperty(asset.id)
      );
      const voteValue = voteType === "up";
      if (existingVoteIndex !== -1) {
        const vote = prevVotes[existingVoteIndex];
        if (
          (vote[asset.id] && voteType === "up") ||
          (!vote[asset.id] && voteType === "down")
        ) {
          updateUserVotes = prevVotes.filter(
            (_, index) => index !== existingVoteIndex
          );
          removeVote = true;
        } else if (
          (!vote[asset.id] && voteType === "up") ||
          (vote[asset.id] && voteType === "down")
        ) {
          updateUserVotes = [...prevVotes];
          updateUserVotes[existingVoteIndex] = { [asset.id]: voteValue };
          toggleVote = true;
        } else {
          updateUserVotes = [...prevVotes];
          updateUserVotes[existingVoteIndex] = { [asset.id]: voteValue };
        }
      } else {
        updateUserVotes = [...prevVotes, { [asset.id]: voteValue }];
      }
      return updateUserVotes;
    });
    const { upVote, downVote } = asset;
    let newUpVote = upVote || 0;
    let newDownVote = downVote || 0;
    if (removeVote) {
      if (voteType === "up") newUpVote -= 1;
      else newDownVote -= 1;
    } else if (toggleVote) {
      if (voteType === "up") {
        newUpVote += 1;
        newDownVote -= 1;
      } else {
        newUpVote -= 1;
        newDownVote += 1;
      }
    } else {
      if (voteType === "up") newUpVote += 1;
      else newDownVote += 1;
    }
    setAssets((prevAssets) => {
      return prevAssets.map((item) => {
        if (item.id === asset.id) {
          return {
            ...item,
            upVote: newUpVote,
            downVote: newDownVote,
            userVote: removeVote ? null : voteType === "up" ? true : false,
          };
        }
        return item;
      });
    });
    const updatedAsset = {
      ...asset,
      upVote: newUpVote,
      downVote: newDownVote,
    };
    setVoteDetails({
      asset: updatedAsset,
      removeVote,
      voteType,
      updateUserVotes,
    });
  };
  const updateVoteDetails = utility.debounce(async () => {
    if (voteDetails) {
      const { asset, updateUserVotes } = voteDetails;
      try {
        const updates = [
          {
            collection: "partnerDetails",
            id: user.uid,
            data: { votes: updateUserVotes },
          },
          {
            collection: "vtec_assets",
            id: asset.id,
            data: {
              upVote: asset.upVote,
              downVote: asset.downVote,
            },
          },
        ];
        await updateMultipleDocuments(updates);
        dispatchUser({
          type: "VOTE_CHANGE",
          payload: {
            ...user,
            votes: userVotes,
          },
        });
      } catch (error) {
        console.error("Error updating documents: ", error);
      }
      // Clear voteDetails to avoid re-running this effect
      setVoteDetails(null);
    }
  }, 500);
  useEffect(() => {
    updateVoteDetails();
  }, [voteDetails]);
  return (
    <Grid container justifyContent={"center"} className="vtec-container">
      <Navbar sx={{ backgroundColor: "grey" }} />

      <article
        className={`px-6 xl:px-24 w-full sticky top-[65px] z-10 bg-[${theme.palette.background.default}]`}
      >
        <Grid
          container
          className="lg:flex md:!flex-nowrap"
          gap={2}
          paddingY={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <article className="md:flex items-center gap-2 md-gap-3 w-full ">
            <Grid item xs className="!mb-3 md:!mb-0">
              <Searchpanel />
            </Grid>
            <Grid item xs>
              <Freesolo />
            </Grid>
            <Grid item xs>
              <Filter />
            </Grid>
          </article>
        </Grid>
      </article>
      <Grid
        className="assets-cards"
        container
        paddingBottom={10}
        sx={{ padding: "0px 10px" }}
      >
        {loader ? (
          <ProgressLoader />
        ) : (
          <Grid container gap={6} justifyContent={"center"} marginTop={2}>
            {assets.length > 0 &&
              assets.map((asset) => {
                return (
                  <AssetCard
                    item
                    key={asset.id}
                    asset={asset}
                    onVoteClick={handleVote}
                  ></AssetCard>
                );
              })}
          </Grid>
        )}
      </Grid>

      {selectedAsset && <Assetmodal />}
      <Ipad />
    </Grid>
  );
}
