import React from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import "./PrivateAccounts.css";

export default function ThemeSelector({
  handleColorChange,
  isDefaultChecked,
  handleCheckboxChange,
  selectedTheme,
  previewTheme
}) {
  return (
    <Stack direction="row" spacing={2} className="flex-wrap lg:flex-nowrap">
      <Grid item xs={12} sm={7}>
        {Object.entries(selectedTheme).map(([key, value]) => (
          <div key={key} className="color-section">
            <Typography className="color-label">
              {camelToTitleCase(key)}
            </Typography>
            <input
              type="color"
              className="color-input"
              value={value || ""}
              onChange={(e) => handleColorChange(e, key)}
            />
          </div>
        ))}
        <FormControlLabel
          control={<Checkbox />}
          label="Default"
          checked={isDefaultChecked}
          onChange={handleCheckboxChange}
        />
      </Grid>
      <Grid container className="!ml-0 lg:!ml-2">
        {previewTheme}
      </Grid>
    </Stack>
  );
}

function camelToTitleCase(camelCaseString) {
  return camelCaseString
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}
